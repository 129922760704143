import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/SecretaryDashboardPage";

const Students = lazy(() => import("./modules/Students/pages/StudentPage"));
const Teachers = lazy(() => import("./modules/Teachers/pages/TeacherPage"));
const Classes = lazy(() => import("./modules/Classes/pages/ClassesPage"));
const Lectures = lazy(() => import("./modules/Lectures/pages/LecturePage"));
const Secretary = lazy(() => import("./modules/Secretary/pages/SecretaryPage"));

export default function AdminPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/students" component={Students} />
        <Route path="/teachers" component={Teachers} />
        <Route path="/classes" component={Classes} />
        <Route path="/lectures" component={Lectures} />
        {/* <Route path="/exams" component={Exams} /> */}
        <Route path="/secretary" component={Secretary} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
