import React, { useEffect, useState } from "react";
import Axios from "axios";
import Lessons from "./LessonCard";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import Tabs from "./maintabs";
export default function StudentLesson() {
  const [lessons, setLessons] = useState([]);
  const [dynamic, setDynamic] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getLessons = {
      method: "get",
      url: "https://london-eye.onrender.com/api/v1/student/my-exam",
    };
    setLoading(true);
    Axios(getLessons)
      .then((response) => {
        setLessons(
          response.data.sort(
            ({ id: previousID }, { id: currentID }) => currentID - previousID
          )
        );

        setDynamic(response.data);
        setLoading(false);
      })
      .catch(function(error) {
        setLoading(false);
      });
  }, []);

  console.log(lessons);
  const LessonList = lessons.map((item) => (
    <Link
      to={
        item.is_submitted === "true"
          ? `/exams/exams/`
          : item.remain_day === "Not Active"
          ? "/exams/exams/"
          : item.remain_day === "Active"
          ? `/exams/exams/${item.id}`
          : "/exams/exams"
      }
    >
      <Lessons
        desc={
          item.is_submitted === "true"
            ? "Exam is succesfully submitted"
            : item.remain_day === "Not Active"
            ? " Exam has been passed"
            : item.remain_day === "Active"
            ? "Exam is Active "
            : `Exam has ${item.remain_day} days to start`
        }
        time={item.is_submitted === "true" ? "" : item.exam_start_date}
        name={item.exam_name}
        submit={item.is_submitted}
        day={item.remain_day}
        score={item.score}
      />
    </Link>
  ));

  if (loading) {
    return (
      <ReactLoading
        type={"spinningBubbles"}
        color={"#f0f0f0"}
        height={667}
        width={375}
      />
    );
  }

  if (!dynamic.length) {
    return (
      <div style={{ height: "100px", background: "transparent" }}>
        <span style={{ fontSize: "20px", color: "white" }}>
          There is no result.
        </span>
      </div>
    );
  }
  const getLessons = {
    method: "get",
    url: "https://london-eye.onrender.com/api/v1/student/my-exam",
  };

  const setExams = (val) => {
    console.log("val", val);
    if (val === "all") {
      Axios(getLessons)
        .then((response) => {
          setLessons(
            response.data.sort(
              ({ id: previousID }, { id: currentID }) => currentID - previousID
            )
          );
        })
        .catch(function(error) {});
    }

    if (val === "Coming") {
      const data = dynamic.filter((item) => {
        return item.remain_day != "Active" && item.remain_day != "Not Active";
      });
      setLessons(data);
    }
    if (val === "Active") {
      const data = dynamic.filter((item) => {
        return item.remain_day === "Active" && item.is_submitted != "true";
      });

      setLessons(data);
    }
    if (val === "Completed") {
      const data = dynamic.filter((item) => {
        return item.is_submitted === "true";
      });

      setLessons(data);
    }
    if (val === "Missed") {
      const data = dynamic.filter((item) => {
        return (
          item.is_submitted === "false" && item.remain_day === "Not Active"
        );
      });

      setLessons(data);
    }
  };

  return (
    <>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <Tabs set={(e) => setExams(e)}>
          <div
            style={{
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {LessonList}
          </div>
        </Tabs>
      </div>
    </>
  );
}
