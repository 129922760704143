import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/TeacherDashboardPage";

const Exams = lazy(() => import("./modules/TeacherExams/pages/ExamPage"));
const Completed = lazy(() =>
  import("./modules/TeacherCompletedExams/pages/ExamPage")
);
const Homework = lazy(() =>
  import("./modules/TeacherHomework/pages/LecturePage")
);

const Certificate = lazy(() =>
  import("./modules/TeacherKarneCreater/pages/StudentPage")
);

const CompletedHomework = lazy(() =>
  import("./modules/TeacherCompletedHomework/pages/ExamPage")
);

export default function AdminPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/completed" component={Completed} />
        <Route path="/exams" component={Exams} />
        <Route path="/completedhomework" component={CompletedHomework} />
        <Route path="/homework" component={Homework} />
        <Route path="/certificates" component={Certificate} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
