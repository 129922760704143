import React, { useEffect, useState } from "react";
import Axios from "axios";
import Card from "./Card";
import classes from "./karne.module.scss";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";

const StudentKarne = () => {
  const [karne, setKarne] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getKarne = {
      method: "get",
      url: "https://london-eye.onrender.com/api/v1/student/karne/list/",
    };
    setLoading(true);
    Axios(getKarne)
      .then((response) => {
        setKarne(response?.data?.results);
        setLoading(false);
      })
      .catch(function(error) {
        setLoading(false);
      });
  }, []);
  console.log("karne", karne);

  if (loading) {
    return (
      <ReactLoading
        type={"spinningBubbles"}
        color={"#f0f0f0"}
        height={667}
        width={375}
      />
    );
  }

  if (!karne) {
    return (
      <div style={{ height: "100px", background: "transparent" }}>
        <span style={{ fontSize: "20px", color: "white" }}>
          There is no result.
        </span>
      </div>
    );
  }

  return (
    <div className={classes.karneContainer}>
      {karne &&
        karne?.map((item) => (
          <Link to={`/certificates/certificates/${item.id}`}>
            <Card
              details={item}
              onClick
              teacher={`${item.creator.first_name} ${item.creator.last_name}`}
            />
          </Link>
        ))}
    </div>
  );
};

export default StudentKarne;

// api/v1/student/karne/list/
// api/v1/student/karne/detail/id
