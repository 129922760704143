import React, { useEffect, useState } from "react";
import Axios from "axios";
import Lessons from "./LessonCard";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";

export default function StudentLesson() {
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getLessons = {
      method: "get",
      url: "https://london-eye.onrender.com/api/v1/student/my-lectures",
    };
    setLoading(true);
    Axios(getLessons)
      .then((response) => {
        setLoading(false);

        setLessons(response.data);
        console.log(response.data);
      })
      .catch(function(error) {
        setLoading(false);
      });
  }, []);

  const LessonList = lessons.map((item) => (
    <Link to={`/lessons/lessons/${item.id}`}>
      <Lessons name={item.lecture_name} desc={item.lecture_description} />
    </Link>
  ));

  if (loading) {
    return (
      <ReactLoading
        type={"spinningBubbles"}
        color={"#f0f0f0"}
        height={667}
        width={375}
      />
    );
  }

  if (!lessons.length) {
    return (
      <div style={{ height: "100px", background: "transparent" }}>
        <span style={{ fontSize: "20px", color: "white" }}>
          There is no result.
        </span>
      </div>
    );
  }

  return (
    <>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <div
          style={{
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {LessonList}
        </div>
      </div>
    </>
  );
}
