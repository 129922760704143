import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red, green } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import Check from "../checked.svg";
import Comment from "./comments.svg";
import ReactTooltip from "react-tooltip";
import classssses from "../askDemoLink.module.scss";
const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    margin: 20,
    minWidth: 345,
    flexBasis: 17,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  navatar: {
    backgroundColor: green[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }
  console.log(props);
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="Recipe"
            className={
              props.day === "Not Active" ? classes.avatar : classes.navatar
            }
          >
            {props.name && props.name.slice(0, 1)}
          </Avatar>
        }
        title={props.name}
        subheader={props.sub}
      />
      <div style={{ position: "relative" }}>
        {props.submit === "true" && (
          <img
            src={Check}
            style={{
              position: "absolute",
              width: "40%",
              right: "30%",
              top: "10%",
            }}
          />
        )}
        <CardMedia
          className={classes.media}
          image={
            props.day === "Not Active"
              ? props.submit === "true"
                ? "https://images.pexels.com/photos/267669/pexels-photo-267669.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                : "https://images.pexels.com/photos/1171386/pexels-photo-1171386.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              : "https://images.pexels.com/photos/2923/young-game-match-kids.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          }
          title="Paella dish"
        />
      </div>
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.desc}
        </Typography>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        {props.submit != "true" ? (
          <React.Fragment>
            <span style={{ fontSize: "16px", margin: "10px" }} color="primary">
              <span style={{ fontSize: "12px", fontWeight: "700" }}>Day: </span>
              <br /> {props.time && props.time.slice(0, 10)}
            </span>
            <span style={{ fontSize: "16px", margin: "10px" }} color="primary">
              <span style={{ fontSize: "12px", fontWeight: "700" }}>
                Hour:{" "}
              </span>
              <br />
              {props.time && props.time.slice(11, 16)}
            </span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span style={{ fontSize: "16px", margin: "10px" }} color="primary">
              <img
                data-tip={props.comment ? props.comment : "No comment"}
                style={{ width: "40px", height: "40px", margin: "2px auto" }}
                src={Comment}
              />
            </span>
            <img
              style={{ width: "40px", height: "40px", margin: "0 auto" }}
              src={Check}
            />
            <span style={{ fontSize: "16px", margin: "10px" }} color="primary">
              <span style={{ fontSize: "14px", fontWeight: "700" }}>Score</span>
              <br />
              {props.score}
            </span>
          </React.Fragment>
        )}
        {/* <Button size="small" color="primary">
          {props.submit === "true" ? "You Completed Exam" : "You Passed Exam"}
        </Button> */}
      </CardActions>
      <ReactTooltip
        effect="solid"
        multiline={true}
        getContent={(dataTip) => (
          <div
            style={{
              maxWidth: "150px",
              width: "100%",
              border: "1px solid #1d1d1d",
            }}
          >
            {props.comment ? props.comment : "No Comment"}
          </div>
        )}
      />
    </Card>
  );
}
