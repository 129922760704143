import React from "react";
import { Route } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import Payment from "./Payment";
import PaymentCheck from "./PaymentCheck";
import Prices from "./Prices";
import Check from "./CheckPayment";
function App() {
  return (
    <div>
      <Route exact path="/" component={Prices} />
      <Route path="/checkout" component={Payment} />
      <Route path="/checkoutForm" component={CheckoutForm} />

    </div>
  );
}

export default App;
