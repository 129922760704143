/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Formik, Form, Field } from "formik";
import Axios from "axios";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { set } from "lodash";
import classes from "./askDemoLink.module.scss";
import DatePicker from "react-datepicker";

export function TilesWidget14({ className }, props) {
  // useEffect(() => {
  //   getStudents();
  // }, []);
  const backgroundImageUrl = toAbsoluteUrl("/media/bg/londoneyeattendance.jpg");
  const [open, setOpen] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [classList, setClassList] = useState([]);

  const [selectedClass, setSelectedClass] = useState();
  const [selectedStudent, setSelectedStudent] = useState();
  const [startDate, setStartDate] = useState(new Date());

  const closeModal = () => setOpen(false);

  useEffect(() => {
    const getClassList = {
      method: "get",
      url: "https://london-eye.onrender.com/api/v1/user/admin/class-list",
    };

    Axios(getClassList)
      .then((response) => {
        console.log(response.data.results);
        const optionEx = response.data.results.map((a) => ({
          value: a.id,
          label: a.Class_name,
        }));
        setClassList(optionEx);
      })
      .catch(function(error) {});
  }, []);

  const setStudents = (selectedClass) => {
    const getStudents = {
      method: "get",
      url: `https://london-eye.onrender.com/api/v1/user/user-list2?userType=student&&student_class=${selectedClass}`,
    };

    Axios(getStudents)
      .then((response) => {
        const optionEx = response.data.map((a) => ({
          value: a.id,
          label: `${a.first_name} ${a.last_name} `,
        }));
        setStudentList([]);
        setStudentList(optionEx);
      })
      .catch(function(error) {});
  };

  const onSubmitForm = () => {
    const postExam = {
      method: "post",
      url: "https://london-eye.onrender.com/api/v1/staff/create-attendance",
      data: {
        attendance_day: startDate,
        student: selectedStudent,
      },
    };
    Axios(postExam)
      .then((response) => {
        if (response.status === 201) {
          closeModal();
        }
      })
      .catch(function(error) {});
  };

  return (
    <>
      <div
        className={`card card-custom bgi-no-repeat bgi-size-cover ${className}`}
        style={{
          backgroundImage: `url("${backgroundImageUrl}")`,
        }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex flex-column align-items-start justify-content-start">
          <div className="p-1 flex-grow-1">
            <h3 className="text-white font-weight-bolder line-height-lg mb-5">
              Devamsızlık Girişi
            </h3>
          </div>
          <a
            onClick={() => setOpen(true)}
            className="btn btn-link btn-link-warning font-weight-bold"
          >
            Rapor Oluştur
            <span className="svg-icon-lg svg-icon-warning">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Arrow-right.svg"
                )}
              />
            </span>
          </a>
          <Popup
            open={open}
            modal
            nested
            position="center center"
            closeOnDocumentClick
            onClose={closeModal}
          >
            <>
              <Formik onSubmit={() => {}}>
                {({ handleSubmit, setFieldValue }) => (
                  <>
                    <Modal.Body className="overlay overlay-block cursor-default">
                      <Form className="form form-label-right">
                        <div className="form-group row">
                          <div className="col-lg-12">
                            <div className="form-group">
                              <div className="col-lg-12">
                                <label className={classes.datePickerLabel}>
                                  Select Class
                                </label>
                                <Field
                                  name={"selectedClass"}
                                  placeholder={"Select Class"}
                                  component={Select}
                                  style={{ zIndex: "9999999 !important" }}
                                  value={classList.filter(
                                    (option) => option.value === selectedClass
                                  )}
                                  onChange={(val) => {
                                    setSelectedClass(val.value);
                                  }}
                                  type={selectedClass}
                                  tabSelectsValue={selectedClass}
                                  options={classList}
                                />
                              </div>
                              <div className="col-lg-12">
                                <label className={classes.datePickerLabel}>
                                  Select Student
                                </label>
                                <Field
                                  name={"selectedClass"}
                                  placeholder={"Select Student"}
                                  component={Select}
                                  style={{ zIndex: "9999999 !important" }}
                                  value={studentList.filter(
                                    (option) => option.value === selectedStudent
                                  )}
                                  onChange={(val) =>
                                    setSelectedStudent(val.value)
                                  }
                                  type={selectedStudent}
                                  tabSelectsValue={selectedStudent}
                                  options={studentList}
                                />
                              </div>
                              <div
                                className="col-lg-12"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className={classes.datePickerLabel}>
                                  Attendance Date Time
                                </label>
                                <Field
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  timeCaption="time"
                                  dateFormat="MMMM d, yyyy h:mm aa"
                                  component={DatePicker}
                                  className={classes.datePicker}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        onClick={closeModal}
                        className="btn btn-light btn-elevate"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          onSubmitForm();
                        }}
                        type="submit"
                        className="btn btn-primary btn-elevate"
                      >
                        Save
                      </button>
                    </Modal.Footer>
                  </>
                )}
              </Formik>
            </>
          </Popup>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
