import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Form from "../CheckoutForm";
export default function PaymentForm() {
  return (
    <React.Fragment>
      <Form />
    </React.Fragment>
  );
}
