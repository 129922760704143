/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import ReactLoading from "react-loading";
import { toAbsoluteUrl } from "../../../_helpers";
import { Link } from "react-router-dom";
export function ListsWidget11({ className, homeworks }) {
  const date = new Date();
  const seconds = date.getTime() / 1000;

  const LessonList = homeworks.map((item, index) => (
    <Link to={`/homework/homework/${item.id}`}>
      <div
        className={
          item.remain_day === "Not Active"
            ? `d-flex align-items-center bg-light-danger rounded p-5 mb-9`
            : `d-flex align-items-center bg-light-success rounded p-5 mb-9`
        }
      >
        <span className="svg-icon svg-icon-success mr-5 svg-icon-lg">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          ></SVG>
        </span>
        <div className="d-flex flex-column flex-grow-1 mr-2">
          <a
            href="#"
            className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
          >
            {item.HomeWork_name}
          </a>
          <span className="text-muted font-weight-bold">
            Due in {item.remain_day} days
          </span>
        </div>
      </div>
    </Link>
  ));

  return (
    <>
      <div className={`card card-custom ${className}`}>
        <div
          style={{
            height: "50%%",
            width: "50%",
            display: "flex",
            justifyContent: "center",
          }}
        ></div>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">Homework</h3>
          <div className="card-toolbar"></div>
        </div>
        {!homeworks.length ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "90%",
              height: "100%",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#C9F7F5"}
              height={350}
              width={350}
            />
          </div>
        ) : (
          <div className="card-body pt-0">{LessonList}</div>
        )}
      </div>
    </>
  );
}
