import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { shallowEqual, useSelector } from "react-redux";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    // top: `${top}%`,
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
  };
}

function getCurrentDate(separator = ":") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${date}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "scroll",
    flexDirection: "column",
    display: "flex",
    padding: "10px",
    height: "80%",
    top: "10%",
    right: "15%",
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h3>LONDON EYE MESAFELİ SATIŞ SÖZLEŞMESİ</h3>
      <p>
        İşbu Sözleşme (“Sözleşme”), ALICI’nın (“London Eye Eğitim Portalı
        Kullanıcısı”) SATICI’ya (“London Eye”) ait
        “https://londoneyedilokullari.com/” internet sitesi üzerinden sipariş
        vererek satın almak istediği aşağıda belirtilen ürün/hizmetlerin
        ALICI’ya satışı-teslimi ve diğer hususlara ilişkin olarak Tarafların hak
        ve yükümlülüklerini düzenler. ALICI, bu Sözleşme’yi İNTERNET SİTESİ’nde
        onayladıktan sonra, sipariş verdiği ürün/hizmetlerin bedeli ve
        masrafları seçtiği ödeme yöntemi ile tahsil olunur.
      </p>
      <h3>MADDE 1. TARAFLAR</h3>
      <h3>SATICI</h3>
      <p>
        Unvanı : Sağlam Eğitim Kurumları Özel Eğitim Organizasyon Hizmetleri
        Danışmanlık Yayıncılık Ltd. Şti.
        <br /> MERSİS No : 0737050122000018
        <br /> Adres : Cumhuriyet Mh. Hüsamoğlu Sk. No:5 61030 Ortahisar/TRABZON
        <br />
        Telefon : (0462) 800 00 08 E-posta : londoneyedilokulu@gmail.com
      </p>
      <h3>ALICI</h3>
      Adı Soyadı : {props.name}
      <br />
      Adres :{props.adress} <br />
      Telefon :{props.phone} <br />
      E-posta :{props.email}
      <p></p>
      <h3>MADDE 2. SÖZLEŞMENİN KONUSU</h3>
      <p>
        İşbu Sözleşme’nin konusu, ALICI’nın SATICI’ya ait
        “https://londoneyedilokullari.com/” internet sitesi üzerinden elektronik
        ortamda sipariş verdiği aşağıda nitelikleri ve satış fiyatı belirtilen
        ürün/hizmetin satışı ve teslimi ile ilgili olarak 6502 sayılı
        Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeleri
        Uygulama Esas ve Usulleri Hakkında Yönetmelik hükümleri gereğince
        tarafların hak ve yükümlülüklerinin saptanmasıdır. ALICI’nın Türk
        Ticaret Kanunu’na göre tacir olması durumunda Tüketici Haklarına ilişkin
        hükümler uygulanmaz.
        <br /> ALICI, satışa konu ürün/hizmetin temel niteliklerini, satış
        fiyatı, ödeme şekli, teslimat koşulları vs. satışa konu ürün ile ilgili
        tüm ön bilgiler ve “cayma” hakkı konusunda bilgi sahibi olduğunu, bu ön
        bilgileri elektronik ortamda teyit ettiğini ve sonrasında ürün/hizmeti
        sipariş verdiğini işbu sözleşme hükümlerince kabul ve beyan eder.
        <br />
        “https://londoneyedilokullari.com/” sitesinin ödeme sayfasında yer alan
        ön bilgilendirme ve ALICI tarafından verilen sipariş üzerine düzenlenen
        fatura ile birlikte London Eye Eğitim Portalı Üyelik Sözleşmesi işbu
        Sözleşme’nin ayrılmaz parçalarıdır.
      </p>
      <h3>MADDE 3. SÖZLEŞMENİN TÜRÜ, BEDELİ VE ÖDEME</h3>
      <p>
        Ürün/Hizmetin temel özellikleri (türü, miktarı, özellikleri) SATICI’ya
        ait internet sitesinde yayınlanmaktadır. Listelenen ve sitede ilan
        edilen fiyatlar satış fiyatıdır. İlan edilen fiyatlar ve vaatler
        güncelleme yapılana ve değiştirilene kadar geçerlidir. Süreli olarak
        ilan edilen fiyatlar ise belirtilen süre sonuna kadar geçerlidir. ALICI
        tarafından ödeme tamamlanana kadar SATICI ilgili fiyatlarda dilediği
        gibi değişim yapma hakkına sahiptir. Nihai fiyat ALICI’nın ödeme
        ekranında görmüş olduğu fiyattır. Sözleşme konusu mal ya da hizmetin tüm
        vergiler dâhil satış fiyatı aşağıda gösterilmiştir
        <br />
        <br />
        <br />
        Ürün Açıklaması: Eğitim Hizmeti
        <br /> Birim: 1
        <br /> Birim Fiyatı{props.price}TL
        <br /> Toplam:{props.price}TL <br />
        Ödeme Şekli ve Planı:Kredi Kartı
        <br />
        Hizmeti Satın Alan Kişi:{props.name}
        <br />
        Fatura Adresi:{props.adress}
        <br />
        Sipariş Tarihi:{getCurrentDate()}
        <br />
        Hizmet Süresi: 4 ay
        <br />
        <br />
        Ödeme, ALICI tarafından İşbu Sözleşme’nin akdedilmesini takiben Eğitim
        Portalı üzerinden altyapısı iyzi Ödeme ve Elektronik Para Hizmetleri
        A.Ş. (“iyzico”) tarafından sağlanan online ödeme yöntemiyle, ALICI
        tarafından kredi kartı bilgilerinin girilmesiyle gerçekleştirilecektir.
        ALICI, ödeme yöntemine, üyeliğine ve siparişine ilişkin bilgilerin,
        ödemenin gerçekleştirilebilmesi ve ödeme usulsüzlüklerinin önlenmesi,
        araştırılması ve tespit edilmesini temin amacıyla iyzico Ödeme
        Hizmetleri A.Ş.’ye aktarılmasına ve iyzico tarafından
        https://www.iyzico.com/gizlilik-politikasi/ adresindeki Gizlilik
        Politikası’nın en güncel halinde açıklandığı şekilde işlenmesine ve
        saklanmasına rıza göstermektedir. Ürün/hizmete ilişkin bir sevkiyat
        masrafı doğması halinde kargo ücreti ALICI tarafından ödenecektir. ALICI
        ürünü ayrıca sigorta ettirmek isterse bu konudaki mali ve fiili
        sorumluluk kendisinin üzerindedir. SATICI ürünü sigorta ettirmeyecektir.
      </p>
      <h3>MADDE 4. GENEL HÜKÜMLER</h3>
      <p>
        ALICI, SATICI’ya ait internet sitesinde sözleşme konusu ürün/hizmetin
        temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin ön
        bilgileri okuyarak bilgi sahibi olduğunu, elektronik ortamda gerekli
        teyidi verdiğini kabul, beyan ve taahhüt eder. ALICI’nın; Ön
        Bilgilendirmeyi elektronik ortamda teyit etmesi, mesafeli satış
        sözleşmesinin kurulmasından evvel, SATICI tarafından ALICI' ya verilmesi
        gereken adresi, siparişi verilen ürün/hizmetlere ait temel özellikleri,
        ürün/hizmetlerin vergiler dâhil fiyatını, ödeme ve teslimat bilgilerini
        de doğru ve eksiksiz olarak edindiğini kabul, beyan ve taahhüt eder.{" "}
        <br />
        Sözleşme konusu her bir ürün/hizmet, 30 günlük yasal süreyi aşmamak
        kaydı ile ALICI' nın yerleşim yeri uzaklığına bağlı olarak internet
        sitesindeki ön bilgiler kısmında belirtilen süre zarfında ALICI veya
        ALICI’nın gösterdiği adresteki kişi ve/veya kuruluşa teslim edilir. 30
        günlük süre SATICI’nın ALICI’ya bilgi vermesiyle 10 gün uzatılabilir. Bu
        süre içinde ürün/hizmetin ALICI’ya teslim edilememesi durumunda,
        ALICI’nın sözleşmeyi feshetme hakkı saklıdır. Sözleşme konusu
        ürün/hizmet, ALICI'dan başka bir kişi/kuruluşa teslim edilecek ise,
        teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden SATICI
        sorumlu tutulamaz. Kargo veya mücbir sebepli gecikmelerden SATICI
        sorumlu değildir.
        <br />
        SATICI, Sözleşme konusu ürün/hizmeti eksiksiz, siparişte belirtilen
        niteliklere uygun teslim etmeyi/sunmayı, her türlü ayıptan arî olarak
        yasal mevzuat gereklerine göre sağlam, standartlara uygun bir şekilde
        işi doğruluk ve dürüstlük esasları dâhilinde ifa etmeyi, hizmet
        kalitesini koruyup yükseltmeyi, işin ifası sırasında gerekli dikkat ve
        özeni göstermeyi, ihtiyat ve öngörü ile hareket etmeyi kabul, beyan ve
        taahhüt eder. <br />
        SATICI, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan ALICI’yı
        bilgilendirmek ve açıkça onayını almak suretiyle eşit kalite ve fiyatta
        farklı bir ürün/hizmet tedarik edebilir.
        <br />
        <br />
        SATICI, sipariş konusu ürün veya hizmetin yerine getirilmesinin
        imkânsızlaşması halinde sözleşme konusu yükümlülüklerini yerine
        getiremezse, bu durumu, öğrendiği tarihten itibaren 3 gün içinde yazılı
        olarak tüketiciye bildireceğini, 14 günlük süre içinde toplam bedeli
        ALICI’ya iade edeceğini kabul, beyan ve taahhüt eder.
        <br />
        <br />
        ALICI, Sözleşme konusu ürün/hizmetin teslimatı için işbu Sözleşme’yi
        elektronik ortamda teyit edeceğini, herhangi bir nedenle sözleşme konusu
        ürün/hizmet bedelinin ödenmemesi ve/veya banka kayıtlarında iptal
        edilmesi halinde, SATICI’nın sözleşme konusu ürünü/hizmeti teslim/sunma
        yükümlülüğünün sona ereceğini kabul, beyan ve taahhüt eder.
        <br />
        <br />
        ALICI, Sözleşme konusu ürün/hizmetin ALICI veya ALICI’nın gösterdiği
        adresteki kişi ve/veya kuruluşa tesliminden sonra ALICI'ya ait kredi
        kartının yetkisiz kişilerce haksız kullanılması sonucunda sözleşme
        konusu ürün/hizmet bedelinin ilgili banka veya finans kuruluşu
        tarafından SATICI'ya ödenmemesi halinde, ALICI Sözleşme konusu ürünü 3
        gün içerisinde nakliye gideri ALICI’ya ait olacak şekilde SATICI’ya iade
        edeceğini kabul, beyan ve taahhüt eder.
        <br />
        <br />
        SATICI, tarafların iradesi dışında gelişen, önceden öngörülemeyen ve
        tarafların borçlarını yerine getirmesini engelleyici ve/veya geciktirici
        hallerin oluşması gibi mücbir sebepler halleri nedeni ile sözleşme
        konusu ürünü süresi içinde teslim edemez ise durumu ALICI'ya
        bildireceğini kabul, beyan ve taahhüt eder. ALICI da siparişin iptal
        edilmesini, sözleşme konusu ürün/hizmetin varsa emsali ile
        değiştirilmesini ve/veya teslimat/sunma süresinin engelleyici durumun
        ortadan kalkmasına kadar ertelenmesini SATICI’dan talep etme hakkını
        haizdir.
        <br /> <br /> ALICI tarafından siparişin iptal edilmesi halinde
        ALICI’nın nakit ile yaptığı ödemelerde, ürün tutarı 14 gün içinde
        kendisine nakden ve defaten ödenir. ALICI’nın kredi kartı ile yaptığı
        ödemelerde ise ürün/hizmet tutarı, siparişin ALICI tarafından iptal
        edilmesinden sonra 14 gün içerisinde ilgili bankaya iade edilir. ALICI,
        SATICI tarafından kredi kartına iade edilen tutarın banka tarafından
        ALICI hesabına yansıtılmasına ilişkin ortalama sürecin 2 ile 3 haftayı
        bulabileceğini, bu tutarın bankaya iadesinden sonra ALICI’nın
        hesaplarına yansıması halinin tamamen banka işlem süreci ile ilgili
        olduğundan, ALICI, olası gecikmeler için SATICI’yı sorumlu
        tutamayacağını kabul, beyan ve taahhüt eder.
        <br />
        SATICI’nın, ALICI tarafından İnternet Sitesi Üye Kayıt Formunda
        belirtilen veya daha sonra kendisi tarafından güncellenen adresi,
        e-posta adresi, sabit ve mobil telefon hatları ve diğer iletişim
        bilgileri üzerinden mektup, e-posta, SMS, telefon görüşmesi ve diğer
        yollarla iletişim, pazarlama, bildirim ve diğer amaçlarla ALICI’ya
        ulaşma hakkı bulunmaktadır. ALICI, işbu sözleşmeyi kabul etmekle
        SATICI’nın kendisine yönelik yukarıda belirtilen iletişim
        faaliyetlerinde bulunabileceğini kabul ve beyan etmektedir.
        <br />
        <br />
        ALICI, sözleşme konusu hizmet kapsamında ALICI’ya temin edilmesi gereken
        bir ürün olması halinde ürünü teslim almadan önce muayene edecek; ezik,
        kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı mal/hizmeti kargo
        şirketinden teslim almayacaktır. Teslim alınan mal/hizmetin hasarsız ve
        sağlam olduğu kabul edilecektir. Teslimat sırasında kargo personeli
        eşliğinde hasar tespit tutanağı düzenlenmez ise SATICI ALICI’nın iddia
        ettiği zarardan sorumlu tutulmayacaktır. Teslimden sonra mal/hizmetin
        özenle korunması borcu, ALICI’ya aittir.
        <br />
        <br />
        ALICI ile sipariş esnasında kullanılan kredi kartı hamilinin aynı kişi
        olmaması veya ürünün ALICI’ya tesliminden evvel, siparişte kullanılan
        kredi kartına ilişkin güvenlik açığı tespit edilmesi halinde, SATICI,
        kredi kartı hamiline ilişkin kimlik ve iletişim bilgilerini, siparişte
        kullanılan kredi kartının bir önceki aya ait ekstresini yahut kart
        hamilinin bankasından kredi kartının kendisine ait olduğuna ilişkin
        yazıyı ibraz etmesini ALICI’dan talep edebilir. ALICI’nın talebe konu
        bilgi/belgeleri temin etmesine kadar geçecek sürede sipariş dondurulacak
        olup, mezkûr taleplerin 24 saat içerisinde karşılanmaması halinde ise
        SATICI, siparişi iptal etme hakkını haizdir.
        <br />
        <br />
        ALICI, SATICI’ya ait internet sitesine üye olurken verdiği kişisel ve
        diğer sair bilgilerin gerçeğe uygun olduğunu, SATICI’nın bu bilgilerin
        gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları, SATICI’nın ilk
        bildirimi üzerine derhal, nakden ve defaten tazmin edeceğini beyan ve
        taahhüt eder.
        <br />
        <br />
        ALICI, SATICI’ya ait internet sitesini kullanırken yasal mevzuat
        hükümlerine riayet etmeyi ve bunları ihlal etmemeyi baştan kabul ve
        taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve cezai yükümlülükler
        tamamen ve münhasıran ALICI’yı bağlayacaktır.
        <br />
        <br />
        ALICI, SATICI’ya ait internet sitesini hiçbir şekilde kamu düzenini
        bozucu, genel ahlaka aykırı, başkalarını rahatsız ve taciz edici
        şekilde, yasalara aykırı bir amaç için, başkalarının maddi ve manevi
        haklarına tecavüz edecek şekilde kullanamaz. Ayrıca, ALICI başkalarının
        hizmetleri kullanmasını önleyici veya zorlaştırıcı faaliyet (spam,
        virus, truva atı, vb.) işlemlerde bulunamaz.
        <br />
        <br />
        SATICI kendisine ait İnternet Sitesi üzerinden, SATICI’nın kendi
        kontrolünde olmayan ve/veya başkaca üçüncü kişilerin sahip olduğu
        ve/veya işlettiği başka internet sitelerine ve/veya başka içeriklere
        link verilebilir. Bu linkler ALICI’ya yönlenme kolaylığı sağlamak
        amacıyla konmuş olup herhangi bir internet sitesini veya o siteyi
        işleten kişiyi desteklememekte ve link verilen internet sitesinin
        içerdiği bilgilere yönelik herhangi bir garanti niteliği taşımamaktadır.{" "}
        <br />
        <br />
        ALICI, uyuşmazlık hallerinde SATICI’ya şikayetlerini iletebileceği
        iletişim bilgileri ile yasal başvurularını 6502 sayılı Kanun’un ilgili
        hükümlerine uygun olarak İlçe/İl Hakem Heyetlerine ve Tüketici
        Mahkemelerine yapabileceği konusunda bilgilendiğini kabul ve beyan eder.
        <br />
        <br />
        İşbu Sözleşme içerisinde sayılan maddelerden bir ya da birkaçını ihlal
        eden üye işbu ihlal nedeniyle cezai ve hukuki olarak şahsen sorumlu
        olup, SATICI’yı bu ihlallerin hukuki ve cezai sonuçlarından ari
        tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın hukuk alanına intikal
        ettirilmesi halinde, SATICI’nın üyeye karşı Üyelik Sözleşmesine
        uyulmamasından dolayı tazminat talebinde bulunma hakkı saklıdır.
        <h3>
          MADDE 5. EĞİTİM PORTALI KULLANIMINA İLİŞKİN ALICININ HAK VE
          YÜKÜMLÜLÜKLERİ
        </h3>
        <p>
          ALICI/Üye’nin satın almış olduğu hizmetin SATICI tarafından ALICI’ya
          sağlanması SATICI’ya ait İnternet Sitesi’nde yer alan Eğitim
          Portalı’na üye olunması suretiyle Eğitim Portalı aracılığıyla
          gerçekleşecektir.
        </p>
        <br />
        <p>
          ALICI/Üye, İnternet Sitesi’nde yer alan Üyelik Formu’nda yer alan
          bilgilerin doğru olduğunu ve bu bilgilerin gerekli olduğu durumlarda
          bilginin hatalı veya noksan olmasından doğacak zararlardan dolayı
          sorumluluğun kendisine ait olduğunu ve bu hallerden üyeliğinin sona
          erebileceğini, İnternet Sitesi’nde yer alan hizmet ve içerikleri
          kullanırken ileri sürdüğü şahsi fikir, düşünce ve ifadelerin, İnternet
          Sitesi’ne eklediği dosya ve içeriklerin sorumluluğunun kendisine ait
          olduğunu ve London Eye’ın bu içeriklerden dolayı sorumlu
          tutulamayacağını, İnternet Sitesi'ne zarar verecek veya London Eye’ın
          başka internet siteleri ve/veya kurum ve kuruluşlar ile uyuşmazlık
          doğmasına sebebiyet verecek herhangi bir yazılım veya materyal
          bulunduramayacağını, paylaşamayacağını, bu sebeplerden dolayı herhangi
          bir cezai durumun doğması halinde tüm hukuki ve cezai sorumluluğun
          kendisine ait olduğunu, İnternet Sitesi’nde sunulan hizmetlere London
          Eye tarafından belirlenen şekil dışında ve yetkisiz şekilde
          ulaşmamayı, yazılım ile servislerin özelliklerini hiçbir şekilde
          değiştirmemeyi, değiştirilmiş olduğu belli olanları kullanmamayı ve
          sözü geçen maddelere uymadığı durumlarda London Eye’ın uğrayabileceği
          tüm maddi ve manevi zararları ödemeyi, London Eye’dan izin almadan
          İnternet Sitesi’nde yer alan hizmet ve içerikleri ticari ya da reklam
          amacıyla kullanmamayı, kurallara aykırı davrandığı takdirde London
          Eye’ın her türlü hukuki hakkını kullanma ve üyeliğe son verme hakkına
          sahip olduğunu, İnternet Sitesi’nde "Kullanıcı Adı"yla yapacağı her
          türlü işlemden bizzat kendisinin sorumlu olduğunu, kabul, beyan ve
          taahhüt etmiştir.
        </p>
        <br />
        <br />
      </p>
      <h3>
        MADDE 6. SATICININ EĞİTİM PORTALI KULLANIMINA İLİŞKİN HAK VE
        YÜKÜMLÜLÜKLERİ
      </h3>
      <p>
        London Eye, İnternet Sitesi’nin, herhangi bir zamanda çalışmasını geçici
        bir süre askıya alabilir veya tamamen durdurabilir. İnternet Sitesi’nin
        geçici bir süre askıya alınması veya tamamen durdurulmasından dolayı
        London Eye'ın üyelerine veya üçüncü şahıslara karşı hiçbir sorumluluğu
        olmayacaktır. London Eye, İnternet Sitesi hizmetlerinde meydana gelecek
        teknik arızalar nedeniyle ALICI/Üye’nin uğrayacağı zarardan sorumlu
        değildir. London Eye, kendi internet sitesi üstünden yapılan ve zarar
        doğurabilecek her türlü haberleşmeyi, yayını, bilgi aktarımını istediği
        zaman kesme hakkını ve gereken koşullar oluştuğu takdirde Üye
        mesajlarını silme, ALICI/Üye’yi hizmet ve içeriklerden menetme ve
        üyeliğine son verme hakkını saklı tutar. London Eye, İnternet Sitesi'nde
        sunulan hizmet ve içerikleri her zaman değiştirebilme; ALICI/Üye'lerin
        sisteme yükledikleri bilgileri ve içerikleri, ALICI/Üye’ler de dahil
        olmak üzere, üçüncü kişilerin erişimine kapatabilme ve silme hakkını
        saklı tutmaktadır. London Eye, bu hakkını, hiçbir bildirimde bulunmadan
        ve önel vermeden kullanabilir. ALICI/Üye’ler, London Eye’ın talep ettiği
        değişiklik ve/veya düzeltmeleri ivedi olarak yerine getirmek
        zorundadırlar. London Eye tarafından talep edilen değişiklik ve/veya
        düzeltme istekleri, gerekli görüldüğü takdirde, London Eye tarafından
        yapılabilir. London Eye tarafından talep edilen değişiklik ve/veya
        düzeltme taleplerinin ALICI/Üye’ler tarafından zamanında yerine
        getirilmemesi sebebiyle doğan veya doğabilecek zararlar, hukuki ve cezai
        sorumluluklar tamamen ALICI/Üye’lere aittir. London Eye, ALICI/Üye’nin
        Sözleşme’nin herhangi bir hükmünü ihlal etmesi durumunda ALICI/Üye’nin
        üyeliğini iptal ederek sözleşmeyi tek taraflı olarak feshedebilecektir.
      </p>
      <h3></h3>
      <p>
        SATICI’nın İnternet Sitesi ve İnternet Sitesi’nde yer alan hizmet ve
        içeriklerin telif hakkının Sağlam Eğitim Kurumları Özel Eğitim
        Organizasyon Hizmetleri Danışmanlık Yayıncılık Ticaret Limited
        Şirketi’ne ve/veya Sağlam Eğitim Kurumları Özel Eğitim Organizasyon
        Hizmetleri Danışmanlık Yayıncılık Ticaret Limited Şirketi’ne lisans
        veren veya içerik sağlayan kişilere aittir ve 5846 sayılı Fikir ve Sanat
        Eserleri Kanunu ve ilgili diğer mevzuat hükümleri kapsamında koruma
        altındadır. Söz konusu hizmet ve içerikler hiçbir şekilde izinsiz olarak
        çoğaltılamaz, dağıtılamaz, yayımlanamaz ve pazarlanamaz, bunlardan
        türemiş çalışmalar yapılamaz veya hazırlanamaz veya herhangi bir üçüncü
        kişinin erişim veya kullanımına verilemez. Bu kapsamda herhangi bir
        ihlal gerçekleşmesi durumunda ALICI/Üye hukuki ve cezai olarak sorumlu
        tutulacağını kabul, beyan ve taahhüt etmiştir.
      </p>
      <h3>MADDE 8. CAYMA HAKKI VE İSTİSNALARI</h3>
      <p>
        ALICI, sözleşme konusu ürün/hizmetin kendisine veya gösterdiği adresteki
        kişi/kuruluşa tesliminden/sunulmasından itibaren 14 iş gün içinde
        malı/hizmeti reddederek cayma hakkına sahiptir. Cayma hakkının
        kullanılması için bu süre içinde SATICI'ya e-posta veya telefon ile
        bildirimde bulunulması ve ürünün kutu/paket içeriğinin eksilmemesi ve
        ürünün yeniden satılabilir halde olması şarttır. Bu hakkın kullanılması
        halinde, 3. kişiye veya ALICI'ya teslim edilen ürünün SATICI'ya
        gönderildiğine ilişkin kargo teslim tutanağı örneği ile ürün/hizmete
        ilişkin fatura aslının iadesi zorunludur. Fatura aslı gönderilmez ise
        KDV ve varsa sair yasal hükümlülükler iade edilemez. Tüketicinin cayma
        bildiriminin SATICIya ulaştığı tarihten itibaren 10 gün içinde ürün
        bedeli ALICI'ya iade edilir. Cayma hakkı nedeni ile iade edilen ürünün
        kargo bedeli, EFT kesintisi ve sair masraflar SATICI tarafından
        karşılanır. SATICI, herhangi bir neden göstermeksizin, yarım ya da tam
        gerçekleştirilmiş siparişi iptal etme, değiştirme, kabul etmeme ve
        gönderilmiş ürünleri iade isteme hakkını saklı tutar. 14 günlük cayma
        süresi dolmadan önce ALICI’nın, SATICI’dan satın aldığı özel ders
        programını izlemeye başlaması Mesafeli Sözleşmeler Yönetmeliği’nin 15.
        maddesi uyarınca “Cayma Hakkının İstisnası” niteliği taşıdığından,
        ilgili özel ders programına başlanması ile birlikte ALICI, CAYMA HAKKINI
        KULLANAMAYACAKTIR. ALICI’nın isteğine göre özel olarak üretilmiş
        ürün/hizmetlerde açık veya gizli ayıp harici CAYMA HAKKI KULLANILAMAZ.
      </p>
      <h3>MADDE 9. TEMERRÜT HÜKÜMLERİ</h3>
      <p>
        Tarafların işbu Sözleşme’den kaynaklarından edimlerini yerine
        getirmemesi durumunda Borçlar Kanunu'nun 106 ila 108. maddelerinde yer
        alan Borçlunun Temerrüdü hükümleri uygulanacaktır. Temerrüt
        durumlarında, herhangi bir tarafın edimlerini süresi içinde haklı bir
        sebebi olmaksızın yerine getirmemesi durumunda diğer taraf söz konusu
        edimin yerine getirilmesi için edimini yerine getirmeyen tarafa 7 günlük
        süre verecektir. Bu süre zarfında da yerine getirilmemesi durumunda
        edimini yerine getirmeyen taraf mütemerrit olarak addolunacak ve
        alacaklı taraf borcun ifa edilmemesinden dolayı borcun ifasını ve
        gecikme tazminatı isteyebilir ya da karşı tarafa bu haklarından
        vazgeçtiğini bildirmek sureti ile borcun ifa edilmemesinden doğan
        zararının ve giderilmesini isteyebilir ya da sözleşmeden dönebilir.
        Sözleşmeden dönülmesi halinde taraflar karşılıklı olarak ifa
        yükümlülüğünden kurtulur ve daha önce ifa ettikleri edimleri geri
        isteyebilir. Bu durumda borçlu kusursuz olduğunu ispat etmedikçe
        alacaklının sözleşmenin hükümsüz kalması sebebiyle uğradığı zararları
        tazmin etmekle yükümlüdür. Temerrüt halinde uygulanacak faiz oranı
        borcun doğduğu tarihte yürürlükte olan TC. Merkez Bankası (“TCMB”)
        gecelik borç alma faiz oranıdır. SATICI -
        “https://londoneyedilokullari.com/” ’nın işbu yükümlülüğünü yerine
        getirmesini engelleyebilecek mücbir sebepler veya nakliyeyi engelleyen
        hava muhalefetleri, ulaşımın kesilmesi, yangın ,deprem, sel baskını gibi
        olağanüstü olaylar nedeni ile sözleşme konusu ürün/hizmeti süresi
        içerisinde teslim edemez ise, bu tip durumlarda ALICI,
        “https://londoneyedilokullari.com/” 'nın hiçbir sorumluluğu olmadığını,
        siparişin iptal edilmesini veya teslimat süresinin engelleyici durumunun
        ortadan kalkmasına kadar ertelenmesi haklarından birini kullanabilir.
        ALICI’nın siparişi iptal etmesi halinde ödediği tutar 14 iş gün
        içerisinde kendisine ödenir. (Kredi kartı ile yapılan taksitli
        alışverişlerde ise kredi kartına iade için yukarıdaki prosedür ALICI
        tarafından kabul edilir.)
      </p>
      <h3>MADDE 10. SÖZLEŞMEDE DEĞİŞİKLİK YAPILMASI</h3>
      <p>
        Sözleşme hükümlerinde değişiklik ancak Tarafların imzasını taşıyan ek
        sözleşme imzalanması suretiyle gerçekleştirilebilir. Sözleşme’deki
        haklarından feragat eden tarafın yetkilileri tarafından imzalanmış
        yazılı bir feragatname diğer tarafa verilmedikçe taraflardan hiçbiri
        sözleşmede yer alan herhangi bir hakkından feragat etmiş
        sayılmayacaktır. Taraflardan birinin sözleşmeden doğan hak ve
        yükümlülüklerinden herhangi birini yerine getirmekten ihmali veya
        gecikmesi, zımni kabul anlamına gelmeyecektir.
      </p>
      <h3>MADDE 11. ANLAŞMAZLIKLARIN ÇÖZÜMÜ</h3>
      <p>
        Bu sözleşme ve eklerinin uygulanmasından doğabilecek her türlü
        anlaşmazlığın çözümünde Trabzon Mahkemeleri ve İcra Daireleri
        yetkilidir. ALICI’nın Tüketici olduğu durumlarda Tüketici’nin
        korunmasına ilişkin görev ve yetki kuralları uygulanır.
      </p>
      <h3>MADDE 12. YÜRÜRLÜK</h3>
      <p>
        ALICI, Site üzerinden verdiği siparişe ait ödemeyi gerçekleştirdiğinde
        işbu sözleşmenin tüm şartlarını kabul etmiş sayılır. SATICI, siparişin
        gerçekleşmesi öncesinde işbu sözleşmenin sitede ALICI tarafından okunup
        kabul edildiğine dair onay alacak şekilde gerekli yazılımsal
        düzenlemeleri yapmakla yükümlüdür.
      </p>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
