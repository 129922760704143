import React, { useState } from "react";
import ReactAudioPlayer from "react-audio-player";

import classes from "../exam.module.scss";
import CanvasDraw from "react-canvas-draw";
import { TwitterPicker } from "react-color";
function App(props) {
  const [brushColor, setColor] = useState("rgba(155, 12, 60, 0.3)");
  const [img, setImg] = useState();

  return (
    <div className={classes.examContainer}>
      <span className={classes.Heading}>{props.index + 1}</span>
      <span className={classes.QuestionText}>{props.item.email}</span>
      <div>
        <TwitterPicker
          onChange={(val) => {
            console.log(val);
            setColor(val.hex);
          }}
        />
        <div style={{ margin: "20px" }}>
          <ReactAudioPlayer
            src={props.item.audioUrl}
            autoPlay={false}
            controls
          />
        </div>
        {img && <CanvasDraw brushColor={brushColor} imgSrc={img} />}
      </div>
    </div>
  );
}

export default App;
