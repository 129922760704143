import React, { useEffect, useState } from "react";
import Axios from "axios";
import Lessons from "./HomeworkCard";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";

export default function StudentHomeWork() {
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    const getLessons = {
      method: "get",
      url: "https://london-eye.onrender.com/api/v1/student/my-homeworks",
    };
    Axios(getLessons)
      .then((response) => {
        setLessons(
          response.data.sort(
            ({ id: previousID }, { id: currentID }) => currentID - previousID
          )
        );
      })
      .catch(function(error) {});
  }, []);

  const LessonList = lessons.map((item) => {
    return (
      <Link
        to={
          item.is_submitted === "true"
            ? `/homework/homework/`
            : item.remain_day === "Not Active"
            ? `/homework/homework/`
            : item.remain_day === "Active"
            ? `/homework/homework/${item.id}`
            : `/homework/homework/${item.id}`
        }
      >
        <Lessons
          desc={
            item.is_submitted === "true"
              ? "Homework is succesfully submitted"
              : item.remain_day === "Not Active"
              ? "Homework has been passed"
              : item.remain_day === "Active"
              ? "Homework is Active "
              : `Homework has ${item.remain_day} days to start`
          }
          time={item.is_submitted === "true" ? "" : item.HomeWorkDeadLine}
          name={item.HomeWork_name}
          submit={item.is_submitted}
          day={item.remain_day}
          score={item.score}
          comment={item.teacher_comment}
        />
      </Link>
    );
  });

  if (!lessons.length) {
    return (
      <ReactLoading
        type={"spinningBubbles"}
        color={"#f0f0f0"}
        height={667}
        width={375}
      />
    );
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "white",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
        }}
      >
        {LessonList}
      </div>
    </>
  );
}
