import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import AddressForm from "./form";
import PaymentForm from "./paymentForm";
import Review from "./review";
import LEye from "./londoneye1.png";
import PaymentStripe from "./iyzico_payment_logo.png";
import { history } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        London Eye Trabzon
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ["Fatura Adresi", "Ödeme"];

export default function Checkout({ history }) {
  useEffect(() => {
    if (!window) {
      throw new Error("DOM is unavailable");
    }
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    const tawk = document.getElementById("tawkId");
    if (tawk) {
      return window.Tawk_API;
    }
    const script = document.createElement("script");
    script.id = "tawkId";
    script.async = false;
    script.src =
      "https://embed.tawk.to/" + "5f70408f4704467e89f2a221" + "/default";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    const first_script_tag = document.getElementsByTagName("script")[0];
    if (!first_script_tag || !first_script_tag.parentNode) {
      throw new Error("DOM is unavailable");
    }
    first_script_tag.parentNode.insertBefore(script, first_script_tag);
  }, []);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isValid, setValid] = React.useState(false);

  const handleNext = () => {
    const data = window.localStorage.getItem("data");
    if (activeStep === 0 && isValid) {
      setActiveStep(activeStep + 1);
    } else if (activeStep === 0 && !isValid) {
      toast.error("Eksik veya Yanlış Doldurulmuş Alanlar Var");
    } else if (data != null) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <AddressForm isValid={isValid} setValid={setValid} />;
      case 1:
        return <PaymentForm isValid={isValid} setValid={setValid} />;
      case 2:
        return <Review />;
      default:
        throw new Error("Unknown step");
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <img src={LEye} style={{ width: "100px" }}></img>
        </Toolbar>
        <Toolbar onClick={() => history.push("/")}>
          <span>Kurslar</span>
        </Toolbar>
        <Toolbar>
          <span
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.localStorage.removeItem("persist:v706-demo1-auth");
              window.location.reload();
            }}
          >
            Çıkış Yap
          </span>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Ödeme
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Your order number is #2001539. We have emailed your order
                  confirmation, and will send you an update when your order has
                  shipped.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Geri
                    </Button>
                  )}
                  {activeStep === 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === 0 && "Ödemeyi Tamamla"}
                    </Button>
                  )}
                </div>
                <ToastContainer />
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <img
          src={PaymentStripe}
          style={{
            display: "flex",
            alignSelf: "center",
            margin: "20px auto",
            width: "300px",
          }}
        />
        <Copyright />
      </main>
    </React.Fragment>
  );
}
