/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { DropdownCustomToggler, DropdownMenu2 } from "../../dropdowns";
import cambridge from "./indir.jpeg";
import kidsbox from "./klogo.png";
export function ListsWidget8({ className }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Grammar Books
          </h3>
          <div className="card-toolbar"></div>
        </div>
        {/* Body */}
        <div className="card-body pt-0">
          <div className="mb-10">
            <div className="d-flex align-items-center">
              <div
                onClick={() => {
                  window.location =
                    "https://oxforddiscover2e.oxfordonlinepractice.com/";
                }}
                className="symbol symbol-45 symbol-light mr-5"
              >
                <span className="symbol-label">
                  <img className="h-50 align-self-center" src={cambridge} />
                </span>
              </div>

              <div className="d-flex flex-column flex-grow-1">
                <a
                  onClick={() => {
                    window.location =
                      "https://oxforddiscover2e.oxfordonlinepractice.com/";
                  }}
                  href="#"
                  className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  Oxford Lms
                </a>
              </div>
            </div>

            <p
              onClick={() => {
                window.location =
                  "https://oxforddiscover2e.oxfordonlinepractice.com/";
              }}
              className="text-dark-50 m-0 pt-5 font-weight-normal"
            >
              Oxford Learning Management System
            </p>
          </div>

          <div
            onClick={() => {
              window.location = "https://www.kidsboxapps.es/";
            }}
            className="mb-10"
          >
            <div className="d-flex align-items-center">
              <div className="symbol symbol-45 symbol-light mr-5">
                <span className="symbol-label">
                  <img className="h-50 align-self-center" src={kidsbox} />
                </span>
              </div>

              <div
                onClick={() => {
                  window.location = "https://www.kidsboxapps.es/";
                }}
                className="d-flex flex-column flex-grow-1"
              >
                <a
                  onClick={() => {
                    window.location = "https://www.kidsboxapps.es/";
                  }}
                  href="#"
                  className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  Kids Box at Home
                </a>
              </div>
            </div>

            <p
              onClick={() => {
                window.location = "https://www.kidsboxapps.es/";
              }}
              className="text-dark-50 m-0 pt-5 font-weight-normal"
            >
              Kid’s Box at Home is a website full of exciting resources to
              continue learning English while having fun!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
