import React from "react";
import classes from "./card.module.scss";
import medal from "./medal.svg";
const Card = ({teacher, onClick, details}) => {
  return (
    <div className={classes.container} onClick={onClick}>
      <div className={classes.insideContainer}>
        <img
          className={classes.logo}
          src={
            "https://portal.londoneyedilokullari.com/static/media/londoneye2.bc4cdad0.png"
          }
        />
        <img className={classes.medal} src={medal} />
        <span className={classes.title}>Londoneye Language School Report</span>
        <div
          style={{
            display: "flex",
            justifyContent: "stretch",
            width: "100%",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            justifySelf: "flex-end",
            height: "100%",
            paddingBottom: "15px",
          }}
        >
          <span
            style={{
              paddingLeft: "20px",
              display: "flex",
              flex: "1",
              color: "#1d1d1d !important",
            }}
          >
            <i style={{color: "#1d1d1d", fontWeight: "bold"}}>{teacher}</i>
          </span>
          <span className={classes.period}>
            <b>
              {details.education_period && details.education_period.length === 4
                ? `${details.education_period} / ${Number(
                    details.education_period
                  ) + 1}`
                : details.education_period
                ? details.education_period
                : "2020 / 2021"}{" "}
              {details.education_term
                ? `${details.education_term}. Term`
                : "First Term"}
            </b>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Card;
