/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, {useEffect} from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {Routes} from "../app/Routes";
import {I18nProvider} from "../_metronic/i18n";
import {LayoutSplashScreen, MaterialThemeProvider} from "../_metronic/layout";
import firebase from "firebase";
import {
  transitions,
  positions,
  types,
  Provider as AlertProvider,
} from "react-alert";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

firebase.initializeApp({
  apiKey: "AIzaSyDeymF_QQ70Vuh7idBY-l2t6LTNeyVMiYo",
  authDomain: "londoneye-dfad8.firebaseapp.com",
  databaseURL: "https://londoneye-dfad8.firebaseio.com",
  projectId: "londoneye-dfad8",
  storageBucket: "londoneye-dfad8.appspot.com",
  messagingSenderId: "253929274357",
  appId: "1:253929274357:web:aa2e639fd7965c49a17ebc",
  measurementId: "G-3QD34JDW61",
});

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  type: types.SUCCESS,
  // you can also just use 'scale'
  transition: transitions.SCALE,
};
const AlertTemplate = ({style, options, message}) => (
  <div
    style={{
      width: "300px",
      height: "300px",
      backgroundColor: options.type === "1" ? "green" : "red",
      zIndex: "1052 !important",
      borderRadius: "25px",
    }}
  >
    {message}
  </div>
);

export default function App({store, persistor, basename}) {
  // ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    const TRACKING_ID = "UA-217011265-2"; // YOUR_OWN_TRACKING_ID

    ReactGA.initialize(TRACKING_ID);

    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init("4879577628774414", options);
    ReactPixel.pageView(); // For tracking page view
  }, []);
  return (
    <>
      <AlertProvider template={AlertTemplate} {...options}>
        <Provider store={store}>
          {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
          <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
            {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
            <React.Suspense fallback={<LayoutSplashScreen />}>
              {/* Override `basename` (e.g: `homepage` in `package.json`) */}
              <BrowserRouter basename={basename}>
                {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                <MaterialThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    {/* Render routes with provided `Layout`. */}
                    <Routes />
                  </I18nProvider>
                </MaterialThemeProvider>
              </BrowserRouter>
            </React.Suspense>
          </PersistGate>
        </Provider>
      </AlertProvider>
    </>
  );
}
