import React, {useState} from "react";
import {useFormik} from "formik";
import {connect} from "react-redux";
import * as Yup from "yup";
import {Link} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import {register} from "../_redux/authCrud";
import "./popup.css";
import Popup from "reactjs-popup";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  username: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function Registration(props) {
  var currentdate = new Date();
  const {intl} = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    username: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      enableLoading();
      register(
        values.email,
        values.first_name,
        values.last_name,
        values.username,
        values.password
      )
        .then(({data: {}}) => {
          disableLoading();
          props.history.push("/auth/login");
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{display: "block"}}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Üye Ol</h3>
        <p className="text-muted font-weight-bold">
          Kayıt olmak için bilgilerinizi giriniz.
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Fullname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Ad"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "fullname"
            )}`}
            name="first_name"
            {...formik.getFieldProps("first_name")}
          />
          {formik.touched.fullname && formik.errors.fullname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.fullname}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Soyad"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "last_name"
            )}`}
            name="fullname"
            {...formik.getFieldProps("last_name")}
          />
          {formik.touched.fullname && formik.errors.fullname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.fullname}</div>
            </div>
          ) : null}
        </div>
        {/* end: Fullname */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="E-Posta"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Username */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Kullanıcı adı"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "username"
            )}`}
            name="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : null}
        </div>
        {/* end: Username */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Şifre"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Şifreyi Doğrula"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}
        <div className="form-group">
          <Popup
            trigger={
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="acceptTerms"
                  className="m-1"
                  {...formik.getFieldProps("acceptTerms")}
                />
                <Link className="mr-1">
                  Kullanıcı sözleşmesini okudum & kabul ediyorum.
                </Link>
                <span />
              </label>
            }
            modal
            nested
            position="center center"
          >
            <div
              style={{
                overflow: "scroll",
                height: "90vh",
                padding: "15px",
                width: "80vw",
              }}
            >
              <h1>LONDON EYE EĞİTİM PORTALI ÜYELİK SÖZLEŞMESİ</h1>
              <br />
              <h3>MADDE 1.TARAFLAR</h3>
              <br />
              İşbu Sözleşme (“Sözleşme”), bir tarafta Cumhuriyet Mh. Hüsamoğlu
              Sk. No:5 34398 Merkez/TRABZON adresinde mukim Sağlam Eğitim
              Kurumları Özel Eğitim Organizasyon Hizmetleri Danışmanlık
              Yayıncılık Ticaret Limited Şirketi (“London Eye”) ve diğer tarafta
              London Eye’ın sahibi olduğu https://londoneyedilokullari.com/
              internet sitesine üye olan kişi arasında {currentdate.toString()}{" "}
              tarihinde, Üye’nin elektronik olarak onay vermesi ile karşılıklı
              olarak kabul edilerek yürürlüğe girmiştir. London Eye ile Üye işbu
              Sözleşme’de birlikte (“Taraflar”) olarak anılacaktır.
              <br />
              <h3>MADDE 2. TANIMLAR</h3>
              <br />
              Bu Sözleşme’de yazılı; “Eğitim Portalı”, Üye’ye yönelik olarak beş
              ay boyunca verilecek olan dil eğitimi ve sair eğitim-öğretim
              faaliyetlerini gerçekleştirebilmek için çevrimiçi eğitim
              içerikleri, uygulamaları ve hizmetlerini içeren, London Eye
              tarafından geliştirilmiş ve uygulamaya konulmuş dijital platformu
              ifade eder. “İnternet Sitesi”, London Eye Eğitim Portalı’nın
              hayata geçirildiği, https://londoneyedilokullari.com/ isimli alan
              adı ve bu alan adına bağlı alt alan adlarından oluşan siteyi ifade
              eder. “Üye”, https://londoneyedilokullari.com/ sitesi üzerinden
              kaydolmak suretiyle London Eye’ın vermiş olduğu eğitim-öğretim
              faaliyetleri kapsamında Eğitim Portalı üzerinden sunulan hizmet,
              içerik ve uygulamalara işbu Sözleşme’de belirtilen koşullar
              dahilinde erişim sağlayan gerçek kişiyi ifade eder.
              <br />
              <h3>MADDE 3. KONU ve KAPSAM</h3>
              <br />
              İşbu Sözleşme, Eğitim Portalı’nda sunulan hizmet ve içeriklerin,
              bu hizmet ve içeriklerden yararlanma şartlarının ve tarafların hak
              ve yükümlülüklerinin tespitini kapsamaktadır. Sözleşme’nin
              kapsamı, işbu Sözleşme ile İnternet Sitesi içerisinde yer alan,
              sitenin kullanımına, siteye üyeliğe ve site içerisinde yer alan
              hizmet ve içeriklere ilişkin olarak London Eye tarafından yapılmış
              bilcümle uyarı, yazı ve açıklama ve benzeri beyanlardır. Üye,
              Sözleşme hükümlerini kabul etmekle birlikte, İnternet Sitesi
              içerisinde yer alan sitenin kullanımına, siteye üyeliğe ve site
              içerisinde yer alan hizmet ve içeriklere ilişkin her türlü beyanı
              da kabul etmektedir. Üye, bahsi geçen beyanlarda belirtilen her
              türlü hususa uygun olarak hareket edeceğini kabul, beyan ve
              taahhüt eder.
              <br />
              <h3>MADDE 4. Üyenin Hak ve Yükümlülükleri</h3>
              <br />
              Üye, İnternet Sitesi’nde yer alan Üyelik Formu’nda yer alan
              bilgilerin doğru olduğunu ve bu bilgilerin gerekli olduğu
              durumlarda bilginin hatalı veya noksan olmasından doğacak
              zararlardan dolayı sorumluluğun kendisine ait olduğunu ve bu
              hallerden üyeliğinin sona erebileceğini, İnternet Sitesi’nde yer
              alan hizmet ve içerikleri kullanırken ileri sürdüğü şahsi fikir,
              düşünce ve ifadelerin, İnternet Sitesi’ne eklediği dosya ve
              içeriklerin sorumluluğunun kendisine ait olduğunu ve London Eye’ın
              bu içeriklerden dolayı sorumlu tutulamayacağını, İnternet
              Sitesi'ne zarar verecek veya London Eye’ın başka internet siteleri
              ve/veya kurum ve kuruluşlar ile uyuşmazlık doğmasına sebebiyet
              verecek herhangi bir yazılım veya materyal bulunduramayacağını,
              paylaşamayacağını, bu sebeplerden dolayı herhangi bir cezai
              durumun doğması halinde tüm hukuki ve cezai sorumluluğun kendisine
              ait olduğunu, İnternet Sitesi’nde sunulan hizmetlere London Eye
              tarafından belirlenen şekil dışında ve yetkisiz şekilde
              ulaşmamayı, yazılım ile servislerin özelliklerini hiçbir şekilde
              değiştirmemeyi, değiştirilmiş olduğu belli olanları kullanmamayı
              ve sözü geçen maddelere uymadığı durumlarda London Eye’ın
              uğrayabileceği tüm maddi ve manevi zararları ödemeyi, London
              Eye’dan izin almadan İnternet Sitesi’nde yer alan hizmet ve
              içerikleri ticari ya da reklam amacıyla kullanmamayı, kurallara
              aykırı davrandığı takdirde London Eye’ın her türlü hukuki hakkını
              kullanma ve üyeliğe son verme hakkına sahip olduğunu, İnternet
              Sitesi’nde "Kullanıcı Adı"yla yapacağı her türlü işlemden bizzat
              kendisinin sorumlu olduğunu, kabul, beyan ve taahhüt etmiştir.
              <br />
              <h3>MADDE 5. London Eye’ın Hak ve Yükümlülükleri</h3>
              <br />
              London Eye, İnternet Sitesi’nin, herhangi bir zamanda çalışmasını
              geçici bir süre askıya alabilir veya tamamen durdurabilir.
              İnternet Sitesi’nin geçici bir süre askıya alınması veya tamamen
              durdurulmasından dolayı London Eye'ın üyelerine veya üçüncü
              şahıslara karşı hiçbir sorumluluğu olmayacaktır. London Eye,
              İnternet Sitesi hizmetlerinde meydana gelecek teknik arızalar
              nedeniyle Üye’nin uğrayacağı zarardan sorumlu değildir. London
              Eye, kendi internet sitesi üstünden yapılan ve zarar doğurabilecek
              her türlü haberleşmeyi, yayını, bilgi aktarımını istediği zaman
              kesme hakkını ve gereken koşullar oluştuğu takdirde Üye
              mesajlarını silme, Üye’yi hizmet ve içeriklerden menetme ve
              üyeliğine son verme hakkını saklı tutar. London Eye, İnternet
              Sitesi'nde sunulan hizmet ve içerikleri her zaman değiştirebilme;
              Üye'lerin sisteme yükledikleri bilgileri ve içerikleri, Üye’ler de
              dahil olmak üzere, üçüncü kişilerin erişimine kapatabilme ve silme
              hakkını saklı tutmaktadır. London Eye, bu hakkını, hiçbir
              bildirimde bulunmadan ve önel vermeden kullanabilir. Üye’ler,
              London Eye’ın talep ettiği değişiklik ve/veya düzeltmeleri ivedi
              olarak yerine getirmek zorundadırlar. London Eye tarafından talep
              edilen değişiklik ve/veya düzeltme istekleri, gerekli görüldüğü
              takdirde, London Eye tarafından yapılabilir. London Eye tarafından
              talep edilen değişiklik ve/veya düzeltme taleplerinin Üye’ler
              tarafından zamanında yerine getirilmemesi sebebiyle doğan veya
              doğabilecek zararlar, hukuki ve cezai sorumluluklar tamamen
              Üye’lere aittir. London Eye, Üye’nin Sözleşme’nin herhangi bir
              hükmünü ihlal etmesi durumunda Üye’nin üyeliğini iptal ederek
              sözleşmeyi tek taraflı olarak feshedebilecektir.
              <br />
              <h3>MADDE 6. Fikri Mülkiyet Hakları</h3>
              <br />
              London Eye’ın internet Sitesi ve İnternet Sitesi’nde yer alan
              hizmet ve içeriklerin telif hakkının Sağlam Eğitim Kurumları Özel
              Eğitim Organizasyon Hizmetleri Danışmanlık Yayıncılık Ticaret
              Limited Şirketi’ne ve/veya Sağlam Eğitim Kurumları Özel Eğitim
              Organizasyon Hizmetleri Danışmanlık Yayıncılık Ticaret Limited
              Şirketi’ne lisans veren veya içerik sağlayan kişilere aittir ve
              5846 sayılı Fikir ve Sanat Eserleri Kanunu ve ilgili diğer mevzuat
              hükümleri kapsamında koruma altındadır. Söz konusu hizmet ve
              içerikler hiçbir şekilde izinsiz olarak çoğaltılamaz, dağıtılamaz,
              yayımlanamaz ve pazarlanamaz, bunlardan türemiş çalışmalar
              yapılamaz veya hazırlanamaz veya herhangi bir üçüncü kişinin
              erişim veya kullanımına verilemez. Bu kapsamda herhangi bir ihlal
              gerçekleşmesi durumunda Üye hukuki ve cezai olarak sorumlu
              tutulacağını kabul, beyan ve taahhüt etmiştir. Sağlam Eğitim
              Kurumları Özel Eğitim Organizasyon Hizmetleri Danışmanlık
              Yayıncılık Ticaret Limited Şirketi, London Eye Eğitim Portalı
              kapsamında kendi ürettiği ve/veya dışardan satın aldığı bilgi,
              belge, yazılım, tasarım, grafik vb. eserlerin mülkiyet ve
              mülkiyetten doğan telif haklarına sahiptir. Üye’ler başkalarına
              ait fikri ve sınai mülkiyet haklarının korunması için gerekli
              özeni göstermekle yükümlüdür. Üye’ler sadece telif hakları
              kendisine ait olan içeriği İnternet Sitesi’ne yükleyebilir. Eğer
              Üye, fikri ve sınai mülkiyet hakları başkasına ait olan yazı,
              fotoğraf, resim, logo, video ve sair içeriği, söz konusu eser ve
              hak sahiplerinin izni olmaksızın sisteme yüklerse, London Eye
              ihlale konu olan içeriği derhal yayından kaldırmak ve gerektiğinde
              bu ihlali gerçekleştiren Üye’lerin üyeliğine son vermek haklarına
              sahiptir. Üçüncü şahısların fikri ve sınai mülkiyet haklarını
              ihlal eden Üye’ler, bu ihlallerden ve bu ihlallerden doğan her
              türlü zarardan üçüncü şahıslar ile kamu kurum ve kuruluşları
              nezdinde hukuki ve cezai olarak bizzat sorumludurlar.
              <h3>MADDE 7. Ödeme</h3>
              Üye, London Eye tarafından sağlanacak olan beş aylık
              eğitim-öğretim hizmetinin karşılığı olan bedelin tamamının
              ödenmesini işbu Sözleşme’nin akdedilmesini takiben Eğitim Portalı
              üzerinden sağlanan ödeme sistemi vasıtasıyla tarafımızdan bağımsız
              olarak ilgili ödeme sistemi sağlayıcısı şirket aracılığıyla
              gerçekleştirecektir. Üye, beş aylık eğitim-öğretim hizmeti süresi
              dolmaksızın eğitim-öğretim hizmetini almaktan vazgeçse dahi
              Sözleşme uyarınca kararlaştırılan bedelin tamamı veya bir kısmının
              geri ödenmesini talep edemez. Üye (müşteri veya son kullanıcı),
              ödeme yöntemine, üyeliğine ve siparişine ilişkin bilgilerin,
              ödemenin gerçekleştirilebilmesi ve ödeme usulsüzlüklerinin
              önlenmesi, araştırılması ve tespit edilmesini temin amacıyla
              iyzico Ödeme Hizmetleri A.Ş.’ye aktarılmasına ve iyzico tarafından
              https://www.iyzico.com/gizlilik-politikasi/ adresindeki Gizlilik
              Politikası’nın en güncel halinde açıklandığı şekilde işlenmesine
              ve saklanmasına rıza göstermektedir.
              <h3>MADDE 8. Gizlilik ve Bilgi Güvenliği</h3>
              London Eye, Eğitim Portalı’nın kullanılması kapsamında Üye
              tarafından kendisine sağlanan kişisel verileri işbu Sözleşme’de
              yer alan amaçlar dışında kullanmayacak ve üçüncü kişilerle
              paylaşmayacaktır. Bu noktada London Eye, ilgili mevzuat ve
              Taraflar arasında akdedilmiş işbu Sözleşme uyarınca, üçüncü
              kişilere ve Taraflar’a ait her türlü bilgi, belge dahil olmak
              üzere, Üye’lere ait kişisel veriler ve bahsi geçen firmalara ait
              gizlilik arz eden ve ticari iş ve işletme sırrı niteliği taşıyan
              her türlü bilgi, belge ve evrakın üçüncü kişilere bildirmeme,
              ulaştırmama, göstermeme, paylaşmama, London Eye’ın sistemlerinde
              saklanan bilgileri koruma ve gerekli tüm koruma tedbirlerini almak
              ve bu hususta 6698 sayılı Kişisel Verilerin Korunması Kanunu’na
              (“KVKK”) uygun hareket etmekle yükümlüdür. Üye’ler İnternet
              Sitesi’nde yer alan veya Eğitim Portalı’nın kullanılması
              kapsamında Üye’lere sağlanan tüm kişisel verileri KVKK ve ikincil
              mevzuatı hükümlerine uygun olarak saklayacak ve Sözleşme’de
              kararlaştırılmış olan amaçlar dışında kullanılmayacak, üçüncü
              kişilerle kesinlikle paylaşılmayacaktır. Sözleşme kapsamında
              Üye’lere sağlanan yahut İnternet Sitesi’nde yer alan kişisel
              verilerin Üye’ler tarafından KVKK’ya uygun olarak işlenmesi
              noktasında KVKK’nın 3. maddesi uyarınca veri sorumlusu tanımına
              uygun düştüğü her durum için Üye’lerin bizatihi sorumluluğu
              mevcuttur. Üye verilerine London Eye’ın ihmali olmaksızın yetkisiz
              kişilerce erişim sağlanması halinde (üyenin bilgilerini başka
              kişiler ile paylaşması, siteden ayrılırken çıkış yapmaması, ve
              benzeri durumlardan kaynaklı olarak) dolayı gelebilecek
              zararlardan ötürü London Eye’ın sorumluluğu söz konusu
              olmayacaktır.
              <h3>MADDE 9. London Eye Kayıtlarının Geçerliliği</h3>
              Üye, işbu Sözleşme’den doğabilecek ihtilaflarda Sağlam Eğitim
              Kurumları Özel Eğitim Organizasyon Hizmetleri Danışmanlık
              Yayıncılık Ticaret Limited Şirketi’nin ve Eğitim Portalı’nın
              defter kayıtlarıyla, mikrofilm, mikrofiş ve bilgisayar
              kayıtlarının 6100 Sayılı Hukuk Muhakemeleri Kanunu’nun 193.
              maddesi anlamında muteber, bağlayıcı, kesin ve münhasır delil
              teşkil edeceğini ve bu maddenin delil sözleşmesi niteliğinde
              olduğunu beyan ve taahhüt eder.
              <h3>MADDE 10. Uygulanacak Hukuk ve Yetki</h3>
              Taraflar, Sözleşme’nin uygulanmasından ve yorumlanmasından dolayı
              aralarında doğacak her türlü ihtilaf halinde, Türkiye Cumhuriyeti
              kanunlarının uygulanacağını ve İstanbul Mahkemeleri ve İcra
              Daireleri’nin yetkili olduğunu beyan ve taahhüt ederler.
            </div>
          </Popup>
          {/* begin: Terms and Conditions */}

          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={formik.isSubmitting || !formik.values.acceptTerms}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
