import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tab: {
    fontSize: "14px",
  },
});

export default function CenteredTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab
          className={classes.tab}
          onClick={() => props.set("all")}
          label="All Exams"
        />

        <Tab
          className={classes.tab}
          onClick={() => {
            props.set("Active");
          }}
          label="Active Exams"
        />
        <Tab
          className={classes.tab}
          onClick={() => {
            props.set("Coming");
          }}
          label="Coming Exams"
        />
        <Tab
          className={classes.tab}
          onClick={() => props.set("Completed")}
          label="Completed Exams"
        />
        <Tab
          className={classes.tab}
          onClick={() => props.set("Missed")}
          label="Missed Exams"
        />
      </Tabs>
      {props.children}
    </Paper>
  );
}
