import React, { useEffect, useState } from "react";
import classes from "./exam.module.scss";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ReactAudioPlayer from "react-audio-player";
import { Formik, Form, Field, FieldArray } from "formik";
import Q1 from "./Type1";
import Q2 from "./Type2";
import Q3 from "./Type3";
import Q4 from "./Type4";
import Q5 from "./Type5";
import Q6 from "./Type6";
import Q7 from "./Type7";

export const getComponent = (item, index, values, setFieldValue, replace) => {
  switch (item.selectedValue) {
    case "1":
      return (
        <Field
          name={`examQuestions[${index}].selectedValue`}
          index={index}
          item={item}
          component={Q1}
          replace={replace}
          values={values}
        />
      );
    case "2":
      return (
        <Field
          name={`examQuestions[${index}].selectedValue`}
          index={index}
          item={item}
          component={Q2}
          replace={replace}
          values={values}
        />
      );
    case "3":
      return (
        <Field
          name={`examQuestions[${index}].selectedValue`}
          index={index}
          item={item}
          component={Q3}
          replace={replace}
          values={values}
        />
      );
    case "4":
      return (
        <Field
          name={`examQuestions[${index}].selectedValue`}
          index={index}
          item={item}
          component={Q4}
          replace={replace}
          values={values}
        />
      );
    case "5":
      return (
        <Field
          name={`examQuestions[${index}].selectedValue`}
          index={index}
          item={item}
          component={Q5}
          replace={replace}
          values={values}
        />
      );
    case "6":
      return (
        <Field
          name={`examQuestions[${index}].selectedValue`}
          index={index}
          item={item}
          component={Q6}
          replace={replace}
          values={values}
        />
      );
    case "7":
      return (
        <Field
          name={`examQuestions[${index}].selectedValue`}
          index={index}
          item={item}
          component={Q7}
          replace={replace}
          values={values}
        />
      );
    default:
      return (
        <div className={classes.examContainer}>
          <span>Question {index + 1}</span>
        </div>
      );
  }
};
