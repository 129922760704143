import React, { useEffect, useState } from "react";
import Axios from "axios";
import Video from "./VideoCard";
export default function StudentLesson() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const getVideos = {
      method: "get",
      url: "https://london-eye.onrender.com/api/v1/student/my-lecture-videos",
    };
    Axios(getVideos)
      .then((response) => {
        console.log(response);
        setVideos(response.data.results);
      })
      .catch(function(error) {});
  }, []);

  const LessonList = videos.map((item) => (
    <Video
      url={item.video_url}
      name={item.video_title}
      desc={item.lecture_description}
    />
  ));

  return (
    <>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <div
          style={{
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            minHeight: "100vh",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          {LessonList}
        </div>
      </div>
    </>
  );
}
