import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  MixedWidget1,
  MixedWidget14,
  StatsWidget11,
  StatsWidget12,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget1,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8,
  ListsWidget9,
  ListsWidget10,
  ListsWidget11,
} from "../widgets";
import { Link } from "react-router-dom";

export function Demo1Dashboard() {
  const [exams, setExams] = useState([]);
  const [homeworks, setHomeworks] = useState([]);
  useEffect(() => {
    const getHomeworks = {
      method: "get",
      url: "https://london-eye.onrender.com/api/v1/student/my-homeworks",
    };
    const getExams = {
      method: "get",
      url: "https://london-eye.onrender.com/api/v1/student/my-exam",
    };
    Axios(getHomeworks)
      .then((response) => {
        setHomeworks(
          response.data
            .sort(
              ({ id: previousID }, { id: currentID }) => currentID - previousID
            )
            .slice(0, 4)
        );
      })
      .catch(function(error) {});
    Axios(getExams)
      .then((response) => {
        setExams(
          response.data
            .sort(
              ({ id: previousID }, { id: currentID }) => currentID - previousID
            )
            .slice(0, 5)
        );
      })
      .catch(function(error) {});
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>
        <div className="col-xxl-8 order-2 order-xxl-1">
          <ListsWidget11
            homeworks={homeworks}
            className="card-stretch gutter-b"
          />
        </div>
        <div className="col-xxl-8 order-2 order-xxl-1">
          <ListsWidget4 exams={exams} className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
          <ListsWidget8 className="card-stretch gutter-b" />
        </div>
      </div>
    </>
  );
}
