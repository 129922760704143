import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Check from "./checked.svg";
import Comment from "./comments.svg";

const useStyles = makeStyles({
  card: {
    minWidth: 320,
    margin: 20,
    width: 320,
    minHeight: 300,
    height: 300,
  },
  activeCard: {
    minWidth: 320,
    margin: 20,
    width: 320,
    minHeight: 300,
    height: 300,
    border: "1px solid green",
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <Card
      className={props.day === "Active" ? classes.activeCard : classes.card}
    >
      <CardActionArea>
        {props.submit === "true" ? (
          <CardMedia
            className={classes.media}
            image="https://images.pexels.com/photos/3761504/pexels-photo-3761504.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            title={props.name}
          />
        ) : props.day === "Not Active" ? (
          <CardMedia
            className={classes.media}
            image="https://images.pexels.com/photos/1171386/pexels-photo-1171386.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
            title={props.name}
          />
        ) : props.day === "Active" ? (
          <CardMedia
            className={classes.media}
            image="https://images.pexels.com/photos/280277/pexels-photo-280277.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            title={props.name}
          />
        ) : (
          <CardMedia
            className={classes.media}
            image="https://images.pexels.com/photos/4439444/pexels-photo-4439444.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            title={props.name}
          />
        )}

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.desc}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        {props.submit != "true" ? (
          <React.Fragment>
            <span style={{ fontSize: "16px", margin: "10px" }} color="primary">
              <span style={{ fontSize: "12px",fontWeight:"700"  }}>Day: </span>
              <br /> {props.time.slice(0, 10)}
            </span>
            <span style={{ fontSize: "16px", margin: "10px" }} color="primary">
              <span style={{ fontSize: "12px",fontWeight:"700"  }}>Hour: </span>
              <br />
              {props.time.slice(11, 16)}
            </span>
          </React.Fragment>
        ) : (
          <React.Fragment>
                 <span style={{ fontSize: "16px", margin: "10px" }} color="primary">
              <img
              style={{ width: "40px", height: "40px", margin: "2px auto" }}
              src={Comment}
            />
            </span>
            <img
              style={{ width: "40px", height: "40px", margin: "0 auto" }}
              src={Check}
            />
             <span style={{ fontSize: "16px", margin: "10px" }} color="primary">
              <span style={{ fontSize: "14px",fontWeight:"700" }}>Score </span>
              <br />
            {props.score}
            </span>
          </React.Fragment>
        )}
        {/* <Button size="small" color="primary">
          {props.submit === "true" ? "You Completed Exam" : "You Passed Exam"}
        </Button> */}
      </CardActions>
    </Card>
  );
}
