/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import {
  DropdownCustomToggler,
  DropdownMenu1,
  DropdownMenu2,
} from "../../dropdowns";
import { Link } from "react-router-dom";
export function ListsWidget4({ className, exams }) {
  const LessonList = exams.map((item) => (
    <Link to={`/exams/exams/${item.id}`}>
      <div className="d-flex align-items-center mb-10">
        <span
          className={
            item.remain_day === "Not Active"
              ? "bullet bullet-bar bg-info align-self-stretch"
              : '"bullet bullet-bar bg-success align-self-stretch"'
          }
        ></span>

        <label
          className={
            item.remain_day === "Not Active"
              ? "checkbox checkbox-lg checkbox-light-info checkbox-single flex-shrink-0 m-0 mx-4"
              : "checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4"
          }
        >
          <input type="checkbox" name="as" value="1" />
          <span></span>
        </label>

        <div className="d-flex flex-column flex-grow-1">
          <a
            href="#"
            className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
          >
            {item.exam_name}
          </a>
          <span className="text-muted font-weight-bold">
            Due in {item.remain_day} Days
          </span>
        </div>
      </div>
    </Link>
  ));

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">Exams</h3>
        </div>
        {/* Body */}
        <div className="card-body pt-2">{LessonList}</div>
      </div>
    </>
  );
}
