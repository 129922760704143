import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import ReactLoading from "react-loading";
import { Base64 } from "js-base64";
import { shallowEqual, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

export default function CheckOutForm() {
  const script = useRef();
  const [response, setResponse] = useState();
  const [status, setStatus] = useState();

  useEffect(() => {
    const token = window.localStorage.getItem("yToken");
    const configCategoryList = {
      method: "post",
      url: "https://london-eye.onrender.com/api/v1/user/payment-check",
      data: {
        token_and_conversation_id: { token: token },
      },
    };

    Axios(configCategoryList)
      .then((response) => {
        if (response.data.status === "success") {
          setStatus(response.data.status);
        } else {
          setStatus(response.data.status);
        }
        setResponse(response.data);
      })
      .catch(function(error) {});
  }, []);

  if (!response) {
    return (
      <div
        style={{
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ReactLoading
          type={"spinningBubbles"}
          color={"#17c191"}
          height={200}
          width={200}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "green",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {status && status === "success" ? (
        <span style={{ fontSize: "30px", color: "white" }}>Ödeme Başarılı</span>
      ) : (
        <span style={{ fontSize: "20px" }}>Ödeme Başarısız</span>
      )}
    </div>
  );
}
