import React from "react";

import classes from "../exam.module.scss";
import ReactAudioPlayer from "react-audio-player";

function App(props) {
  function createMarkuppp() {
    return { __html: props.item.content };
  }

  const numberOfBlanks = Number(props.item.inputCount);

  var rows = [];
  for (var i = 0; i < numberOfBlanks; i++) {
    rows.push(
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className={classes.QuestionText}> Blank {i + 1}</span>
        <input
          onChange={(val) =>
            props.replace(props.index, {
              email: props.item.email,
              selectedValue: props.item.selectedValue,
              inputCount: props.item.inputCount,
              answers: 10,
            })
          }
          style={{ width: "90%", height: "35px", margin: "5px" }}
        />
      </div>
    );
  }

  const firstRow = rows.slice(0, numberOfBlanks / 2);
  const secondRow = rows.slice(numberOfBlanks / 2, numberOfBlanks);

  return (
    <div className={classes.examContainer}>
      <span className={classes.Heading}>{props.index + 1}</span>
      <span className={classes.QuestionText}>{props.item.email}</span>
      <div style={{ margin: "20px" }}>
        <ReactAudioPlayer src={props.item.audioUrl} autoPlay={false} controls />
      </div>
      <div dangerouslySetInnerHTML={createMarkuppp()} />
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {firstRow}
        </div>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {secondRow}
        </div>
      </div>
    </div>
  );
}

export default App;
