import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {DashboardPage} from "./pages/DashboardPage";

const Students = lazy(() => import("./modules/Students/pages/StudentPage"));
const Teachers = lazy(() => import("./modules/Teachers/pages/TeacherPage"));
const Classes = lazy(() => import("./modules/Classes/pages/ClassesPage"));
const Lectures = lazy(() => import("./modules/Lectures/pages/LecturePage"));
const Secretary = lazy(() => import("./modules/Secretary/pages/SecretaryPage"));
const Exams = lazy(() => import("./modules/Exams/pages/ExamPage"));
const Videos = lazy(() => import("./modules/AdminVideos/pages/LecturePage"));
const Products = lazy(() => import("./modules/Products/pages/ProductPage"));
const Categories = lazy(() =>
  import("./modules/Kategoriler/pages/ProductPage")
);

const Homeworks = lazy(() =>
  import("./modules/AdminHomework/pages/LecturePage")
);

export default function AdminPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/products" component={Products} />
        <Route path="/categories" component={Categories} />
        <Route path="/students" component={Students} />
        <Route path="/teachers" component={Teachers} />
        <Route path="/classes" component={Classes} />
        <Route path="/lectures" component={Lectures} />
        <Route path="/exams" component={Exams} />
        <Route path="/secretary" component={Secretary} />
        <Route path="/videos" component={Videos} />
        <Route path="/homeworks" component={Homeworks} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
