import React, { useEffect, useState } from "react";
import classes from "./karne.module.scss";
import medal from "./Card/medal.svg";
import Axios from "axios";
import ReactStars from "react-rating-stars-component";
import Smile from "./smile.png";
import Cry from "./cry.png";

import {
  AdvancedKarne,
  kb_1_2,
  kb_3_4,
  kb_5_6,
} from "../TeacherKarneCreater/schemas";
const KarneDetail = ({ location }) => {
  const id = location.pathname.split("/certificates/certificates/")[1];
  const [details, setDetails] = useState(false);
  const [content, setContent] = useState(false);

  console.log("details", details);

  useEffect(() => {
    const getLessons = {
      method: "get",
      url: `https://london-eye.onrender.com/api/v1/student/karne/detail/${id}`,
    };
    Axios(getLessons)
      .then((response) => {
        setDetails(response.data);
        console.log("response", response?.data.karne_info);
        if (response?.data?.karne_type === "1") {
          const hehe = response?.data?.karne_info.map((item, index) => {
            return {
              header: item.header,
              skills: item?.skills?.map((a, i) => {
                return {
                  grade: a.grade,
                  title: kb_1_2[index].skills[i].title,
                  trTitle: kb_1_2[index].skills[i].trTitle,
                };
              }),
            };
          });
          setContent(hehe);
        } else if (response.data.karne_type === "2") {
          const hehe = response?.data?.karne_info.map((item, index) => {
            return {
              header: item.header,
              skills: item?.skills?.map((a, i) => {
                return {
                  grade: a.grade,
                  title: kb_3_4[index].skills[i].title,
                  trTitle: kb_3_4[index].skills[i].trTitle,
                };
              }),
            };
          });
          setContent(hehe);
        } else if (response.data.karne_type === "3") {
          const hehe = response?.data?.karne_info.map((item, index) => {
            return {
              header: item.header,
              skills: item?.skills?.map((a, i) => {
                return {
                  grade: a.grade,
                  title: kb_5_6[index].skills[i].title,
                  trTitle: kb_5_6[index].skills[i].trTitle,
                };
              }),
            };
          });
          setContent(hehe);
        } else {
          const hehe = response?.data?.karne_info.map((item, index) => {
            return {
              header: item.header,
              skills: item?.skills?.map((a, i) => {
                return {
                  grade: a.grade,
                  title: AdvancedKarne[index].skills[i].title,
                  trTitle: AdvancedKarne[index].skills[i].trTitle,
                };
              }),
            };
          });
          setContent(hehe);
        }
      })
      .catch(function(error) {});
  }, []);
  return (
    <div className={classes.container}>
      <div className={classes.insideContainer}>
        {/* <img
          className={classes.logo}
          src={
            "https://portal.londoneyedilokullari.com/static/media/londoneye2.bc4cdad0.png"
          }
        /> */}
        <h1
          style={{
            width: "70%",
            margin: "20px auto",
            fontSize: "40px",
            textAlign: "center",
            // marginLeft: "20px",
          }}
        >
          London Eye Language School <br />
          Detailed Report
        </h1>
        <div style={{ display: "flex", width: "100%", padding: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "flex-start",
              justifySelf: "flex-start",
              flex: 1,
            }}
          >
            <h4>
              <b>Student:</b> {details?.student?.first_name}{" "}
              {details?.student?.last_name}
            </h4>
            <h4>
              <b>Class:</b> {details?.Class?.Class_name}
            </h4>
            <h4>
              <b>Teacher:</b> {details?.creator?.first_name}{" "}
              {details?.creator?.last_name}
            </h4>

            {/* <h4>
            <b>Certificate Type:</b> {details?.karne_type}
          </h4> */}
            <h4>
              <b>Education Period:</b>{" "}
              {details.education_period && details.education_period.length === 4
                ? `${details.education_period} / ${Number(
                    details.education_period
                  ) + 1}`
                : details.education_period
                ? details.education_period
                : "2020 / 2021"}{" "}
              {details.education_term
                ? `${details.education_term}. Term`
                : "First Term"}
            </h4>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignSelf: "flex-start",
              justifySelf: "flex-start",
              alignItems: "flex-end",
            }}
          >
            {details?.teacher_comment && (
              <h4 style={{ width: "350px" }}>
                <b>Teacher comment:</b>
                <br /> {details?.teacher_comment}
              </h4>
            )}
          </div>
        </div>
        <img src={medal} className={classes.medalIcon} />

        <div style={{ width: "100%", height: "100%" }}>
          {content &&
            content.map((item) => (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3 style={{ margin: "5px" }}>{item.header}</h3>
                {item.skills.map((a) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        border: "1px solid #1d1d1d",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                          width: "100%",
                          margin: "3px",
                          paddingLeft: "10px",
                          position: "relative",
                        }}
                      >
                        <div>{a.title}</div>
                        <div>{a.trTitle}</div>
                      </div>
                      <div
                        style={{
                          flex: "1",
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <div
                          style={{
                            flex: "0.3",
                            display: "flex",
                            alignSelf: "center",
                          }}
                        >
                          {Number(a?.grade) === 5 && (
                            <img
                              style={{
                                display: "flex",
                                width: "30px",
                                height: "30px",
                              }}
                              src={Smile}
                            />
                          )}

                          {Number(a?.grade) === 1 && (
                            <img
                              style={{
                                display: "flex",
                                width: "30px",
                                height: "30px",
                              }}
                              src={Cry}
                            />
                          )}
                          {Number(a?.grade) === 2 && (
                            <img
                              style={{
                                display: "flex",
                                width: "30px",
                                height: "30px",
                              }}
                              src={Cry}
                            />
                          )}
                        </div>
                        <ReactStars
                          color={"transparent"}
                          {...{
                            size: 30,
                            value: Number(a.grade),
                            edit: false,
                          }}
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default KarneDetail;
