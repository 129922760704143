import React from "react";

import classes from "../exam.module.scss";

function App(props) {
  function createMarkup() {
    return { __html: props.item.content };
  }
  return (
    <div className={classes.examContainer}>
      <span className={classes.Heading}>{props.index + 1}</span>
      <span className={classes.QuestionText}>{props.item.email}</span>
      <div dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
}

export default App;
