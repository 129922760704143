import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  ListsWidget10,
  ListsWidget11,
  AdvanceTablesWidget1,
  MixedWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget12,
  TilesWidget1,
  TilesWidget3,
  TilesWidget10,
  TilesWidget11,
  TilesWidget12,
  TilesWidget13,
  TilesWidget14,
} from "../widgets";

export function Demo2Dashboard() {
  const [teacherCount, setTeacherCount] = useState("");
  const [studentCount, setStudentCount] = useState("");

  useEffect(() => {
    const teacherList = {
      method: "get",
      url: `https://london-eye.onrender.com/api/v1/user/user-list?userType=teacher`,
    };
    const studentList = {
      method: "get",
      url: `https://london-eye.onrender.com/api/v1/user/user-list?userType=student`,
    };
    Axios(teacherList)
      .then((response) => {
        setTeacherCount(response.data.count.toString());
        console.log(response.data);
      })
      .catch(function(error) {});
    Axios(studentList)
      .then((response) => {
        setStudentCount(response.data.count.toString());
      })
      .catch(function(error) {});
  }, []);
  return (
    <>
      {/* begin::Dashboard */}

      {/* begin::Row */}
      <div className="row" style={{ margin: "0 auto", width: "100%" }}>
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <TilesWidget10 className="gutter-b" widgetHeight="150px" />
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <TilesWidget13 className="gutter-b" widgetHeight="175px" />
              <div className="row">
                <div className="col-xl-6">
                  <TilesWidget11
                    count={teacherCount}
                    className="gutter-b"
                    baseColor="primary"
                    widgetHeight="150px"
                  />
                </div>
                <div className="col-xl-6">
                  <TilesWidget12
                    count={studentCount}
                    className="gutter-b"
                    iconColor="success"
                    widgetHeight="150px"
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <TilesWidget14 className="gutter-b card-stretch" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
