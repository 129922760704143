import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ReactLoading from "react-loading";
import Axios from "axios";
import { shallowEqual, useSelector } from "react-redux";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function BasicTable() {
  const classes = useStyles();
  const [attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState(false);

  const { userId } = useSelector(
    ({ auth }) => ({
      userId: auth.user != null && auth.user.id,
    }),
    shallowEqual
  );

  useEffect(() => {
    const getClassList = {
      method: "get",
      url: "https://london-eye.onrender.com/api/v1/student/my-attendence",
    };
    setLoading(true);
    Axios(getClassList)
      .then((response) => {
        setLoading(false);

        const optionEx = response.data.results.map((a) => ({
          value: a.attendance_day,
        }));
        setAttendance(optionEx);
      })
      .catch(function(error) {
        setLoading(false);
      });
  }, []);

  function createData(name, calories, fat, carbs, protein) {
    return { name };
  }

  const rows = [
    createData("01.02.2020", 159, 6.0, 24, 4.0),
    createData("02.02.2020", 237, 9.0, 37, 4.3),
    createData("01.05.2020", 262, 16.0, 24, 6.0),
    createData("01.07.2020", 305, 3.7, 67, 4.3),
  ];

  if (loading) {
    return (
      <ReactLoading
        type={"spinningBubbles"}
        color={"#f0f0f0"}
        height={667}
        width={375}
      />
    );
  }

  if (!attendance.length) {
    return (
      <div style={{ height: "100px", background: "transparent" }}>
        <span style={{ fontSize: "20px", color: "white" }}>
          There is no result.
        </span>
      </div>
    );
  }

  return (
    <div style={{ width: "100%", minHeight: "100vh" }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Absentees</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendance.map((row) => (
              <TableRow key={row.value}>
                <TableCell component="th" scope="row">
                  {row.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
