import React, { useState } from "react";
import classes from "../exam.module.scss";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ReactAudioPlayer from "react-audio-player";

function App(props) {
  const [answers, setAnswers] = useState({
    o1: false,
    o2: false,
    o3: false,
    o4: false,
  });

  return (
    <div className={classes.examContainer}>
      <span className={classes.Heading}>{props.index + 1}</span>
      <span className={classes.QuestionText}>{props.item.email}</span>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value={props.item.option1}
            control={<Checkbox color="primary" />}
            label={props.item.option1}
            labelPlacement="start"
            checked={answers.o1}
            onChange={(val) => {
              setAnswers({
                o1: val.target.checked,
                o2: false,
                o3: false,
                o4: false,
              });
              props.replace(props.index, {
                email: props.item.email,
                selectedValue: props.item.selectedValue,
                option1: props.item.option1,
                option2: props.item.option2,
                option3: props.item.option3,
                option4: props.item.option4,
                answer: {
                  o1: val.target.checked,
                  o2: false,
                  o3: false,
                  o4: false,
                },
              });
            }}
          />
          <FormControlLabel
            value={props.item.option2}
            control={<Checkbox color="primary" />}
            label={props.item.option2}
            labelPlacement="start"
            checked={answers.o2}
            onChange={(val) => {
              setAnswers({
                o1: false,
                o2: val.target.checked,
                o3: false,
                o4: false,
              });
              props.replace(props.index, {
                email: props.item.email,
                selectedValue: props.item.selectedValue,
                option1: props.item.option1,
                option2: props.item.option2,
                option3: props.item.option3,
                option4: props.item.option4,
                answer: {
                  o1: false,
                  o2: val.target.checked,
                  o3: false,
                  o4: false,
                },
              });
            }}
          />
          <FormControlLabel
            value={props.item.option3}
            control={<Checkbox color="primary" />}
            label={props.item.option3}
            labelPlacement="start"
            checked={answers.o3}
            onChange={(val) => {
              setAnswers({
                o1: false,
                o2: false,
                o3: val.target.checked,
                o4: false,
              });
              props.replace(props.index, {
                email: props.item.email,
                selectedValue: props.item.selectedValue,
                option1: props.item.option1,
                option2: props.item.option2,
                option3: props.item.option3,
                option4: props.item.option4,
                answer: {
                  o1: false,
                  o2: false,
                  o3: val.target.checked,
                  o4: false,
                },
              });
            }}
          />
          <FormControlLabel
            value={props.item.option4}
            control={
              <Checkbox
                style={{ width: "30px", height: "30px" }}
                icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                checkedIcon={<CheckBoxIcon fontSize="large" />}
                name="checkedI"
                color="primary"
              />
            }
            checked={answers.o4}
            label={props.item.option4}
            labelPlacement="start"
            onChange={(val) => {
              setAnswers({
                o1: false,
                o2: false,
                o3: false,
                o4: val.target.checked,
              });
              props.replace(props.index, {
                email: props.item.email,
                selectedValue: props.item.selectedValue,
                option1: props.item.option1,
                option2: props.item.option2,
                option3: props.item.option3,
                option4: props.item.option4,
                answer: {
                  o1: false,
                  o2: false,
                  o3: false,
                  o4: val.target.checked,
                },
              });
            }}
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default App;
