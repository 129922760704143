import React from "react";
import { Route } from "react-router-dom";

import Check from "./CheckPayment";
function App() {
  return (
    <div>
      <Route exact path="/check" component={Check} />
    </div>
  );
}

export default App;
