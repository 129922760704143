/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import {Layout} from "../_metronic/layout";
import AdminPage from "./AdminPage";
import StudentPage from "./StudentPage";
import SecretaryPage from "./SecretaryPage";
import TeacherPage from "./TeacherPage";
import Payment from "../app/modules/Payment/index";
import Check from "../app/modules/PaymentCheck/index";
import Modal from "./modal";
import {Logout, AuthPage} from "./modules/Auth";
// import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

export function Routes() {
  const {isAuthorized} = useSelector(
    ({auth}) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const {userType} = useSelector(
    ({auth}) => ({
      userType: auth.user != null && auth.user.userType,
    }),
    shallowEqual
  );
  const {isVerified} = useSelector(
    ({auth}) => ({
      isVerified: auth.user != null && auth.user.student_status,
    }),
    shallowEqual
  );

  const [open, setOpen] = React.useState(false);

  return (
    <Switch>
      <Route component={Logout} path="/logout" />
      <Route path="/check" component={Check} />

      {!isAuthorized ? (
        <>
          <Route component={AuthPage} path="/auth" />
          <Redirect from="/" to="/auth/login" />
        </>
      ) : (
        <div>
          {isAuthorized && userType === "admin" && (
            <>
              <Layout>
                <AdminPage />
              </Layout>
            </>
          )}
          {isAuthorized && userType === "student" && (
            <>
              {isAuthorized && isVerified === "class assigned" ? (
                <Layout>
                  <StudentPage />
                </Layout>
              ) : isVerified === "class assignment pending" ? (
                <>
                  <Modal />
                </>
              ) : (
                <div
                  style={{
                    width: "100%",
                    minHeight: "100vh",
                    backgroundColor: "white",
                    display: "flex",
                  }}
                >
                  <div style={{width: "100%"}}>
                    <Payment />
                    {/* <Route path="/check" component={Check} /> */}
                  </div>
                </div>
              )}
            </>
          )}
          {isAuthorized && userType === "staff" && (
            <Layout>
              <SecretaryPage />
            </Layout>
          )}
          {isAuthorized && userType === "teacher" && (
            <Layout>
              <TeacherPage />
            </Layout>
          )}
        </div>
      )}
    </Switch>
  );
}
