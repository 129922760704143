import React from "react";
import { Route } from "react-router-dom";
import LessonList from "./main";
import Detail from "./lessonDetail";

function App() {
  return (
    <div>
      <Route exact path="/lessons/lessons" component={LessonList} />
      <Route path="/lessons/lessons/:id" component={Detail} />
    </div>
  );
}

export default App;
