import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { encode, decode } from "js-base64";
import { Base64 } from "js-base64";
import Button from "@material-ui/core/Button";
import CheckModal from "./modal.js";
import { shallowEqual, useSelector } from "react-redux";

export default function AddressForm(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tcKimlik, setTcKimlik] = useState("");
  const [adress, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [sozlesme, setSozlesme] = useState(false);
  const { userEmail } = useSelector(
    ({ auth }) => ({
      userEmail: auth.user != null && auth.user.email,
    }),
    shallowEqual
  );

  const [open, setOpen] = useState(false);

  const data = {
    firstName: firstName,
    lastName: lastName,
    tcKimlik: tcKimlik,
    adress: adress,
    postalCode: postalCode,
    phoneNumber: phoneNumber,
    city: city,
  };

  useEffect(() => {
    if (
      firstName.length &&
      lastName.length &&
      adress.length &&
      postalCode.length === 5 &&
      city.length &&
      phoneNumber.length &&
      sozlesme === true
    ) {
      props.setValid(true);
    }
    if (props.isValid === true) {
      const stringdata = Base64.encode(JSON.stringify(data));
      window.localStorage.setItem("data", stringdata);
    }
  });
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Fatura Adresi
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="Ad"
            fullWidth
            autoComplete="given-name"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Soyad"
            fullWidth
            autoComplete="family-name"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Adres"
            fullWidth
            autoComplete="shipping address-line1"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="İl"
            fullWidth
            autoComplete="shipping address-level2"
            onChange={(e) => setCity(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label="Telefon Numarası"
            fullWidth
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Ülke"
            value={"Türkiye"}
            fullWidth
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
          item
          xs={12}
          sm={6}
        >
          <p>
            <span
              onClick={() => setOpen(true)}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Mesafeli Satış Sözleşmesini
            </span>
            <b> Okudum</b> ve
            <b> Kabul Ediyorum.</b>
          </p>
          <Checkbox
            onChange={(e) => setSozlesme(e.target.checked)}
            required
            id="country"
            name="country"
            label="Ülke"
            value={"Türkiye"}
            fullWidth
          />
        </Grid>
      </Grid>
      <CheckModal
        phone={phoneNumber}
        email={userEmail}
        adress={adress}
        name={`${firstName} ${lastName}`}
        open={open}
        price={window.localStorage.getItem("set")}
        setOpen={setOpen}
      />
    </React.Fragment>
  );
}
