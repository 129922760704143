export const AdvancedKarne = [
  {
    header: "Listening(Dinleme)",
    skills: [
      {
        title:
          "Understands natural speaking and instructions and recognizes  advanced words. ",
        trTitle:
          "(Doğal konuşmayı ve komutları anlar,  ileri düzeydeki kelimeleri ayırt eder)",
        grade: "",
      },
      {
        title:
          "Identifies the main theme of an advanced oral text and some specific details without visual aids ",
        trTitle:
          "(İleri düzeyde sözlü bir metnin ana fikrini ve bazı belirli detayları görsel araçlar olmadan tanımlar )",
        grade: "",
      },
    ],
  },
  {
    header: "Talking and Conversation (Konuşma ve Sohbet)",
    skills: [
      {
        title:
          "Recites or sings a  song with correct pronunciation and intonation",
        trTitle:
          "(Doğru telaffuz ve tonlamayla bir şarkıyı ezberden okur ve söyler)",
        grade: "",
      },
      {
        title:
          "Answers the questions orally using the advanced  vocabulary and structures",
        trTitle:
          "(Öğrendiği ileri düzey kelime ve yapıları kullanarak sorulara sözlü olarak cevap verir)",
        grade: "",
      },
      {
        title:
          "Takes part in group conversations and spoken interaction which arises spontaneously",
        trTitle:
          "(Grup konuşmalarına ve kendiliğinden oluşan karşılıklı konuşmalara katılır)",
        grade: "",
      },
      {
        title:
          "Gives personal information and information about his/her  surroundings daily",
        trTitle:
          "(Kendisiyle ilgili ve çevrelerinde gelişen günlük olaylarla ilgili bilgi verebilir)",
        grade: "",
      },
      {
        title: "Gives advanced oral presentations",
        trTitle: "(İleri düzey sözlü sunum yapar)",
        grade: "",
      },
    ],
  },
  {
    header: "Reading (Okuma)",
    skills: [
      {
        title: "Is enthusiastic to read anything in English",
        trTitle: "(İngilizce dilinde herşeyi okumaya heveslidir)",
        grade: "",
      },
      {
        title:
          "Understands well while  reading about randomly chosen passages including unknown words",
        trTitle:
          "( Bilinmeyen kelimeler içeren okuma metinlerini okurken rahat anlar)",
        grade: "",
      },
    ],
  },
  {
    header: "Writing (Yazma)",
    skills: [
      {
        title:
          "Reproduces intermediate words and structures using previously taught patterns and structures",
        trTitle:
          "(Daha önce öğrenilen yapıları kullanarak orta düzey kelimeler ve yapılar türetir)",
        grade: "",
      },
      {
        title: "Writes intermediate stories and descriptions",
        trTitle: "(İleri düzey hikaye ve tanımlamalar yazar)",
        grade: "",
      },
      {
        title:
          "Fills in forms or cards with personal information and data at advanced level",
        grade: "",

        trTitle:
          "(Kişisel bilgi ve verileri form ya da kartlar üzerinde ileri düzey İngilizcesi ile doldurabilir)",
      },
    ],
  },
  {
    header: "Learning to Learn  (Öğrenmeyi öğrenme)",
    skills: [
      {
        title:
          "Shows interest in carrying out the individual activities the teacher supervises",
        trTitle:
          "(Öğretmenin yönettiği bireysel aktiviteleri uygulamaya ilgi gösterir)",
        grade: "",
      },
      {
        title:
          "Accepts the teacher’s instructions and expresses doubts at an intermediate level",
        grade: "",

        trTitle:
          "(Öğretmenin komutlarını anlar, ve anlamadıklarını orta düzey İngilizce kullanarak ifade eder)",
      },
      {
        title: "Always does the Unit activities on-line",
        trTitle: "(Ünite  sonu alıştırmalarını daima yapar)",
        grade: "",
      },
      {
        title: "Takes part in group activities with respect and interest",
        trTitle:
          "(Saygı duyarak ve ilgi göstererek grup aktivitelerine katılır)",
        grade: "",
      },
      {
        title: "Shows interest in learning English",
        trTitle: "(İngilizce öğrenmeye karşı ilgilidir)",
        grade: "",
      },
      {
        title: "Is aware of the class rules and obeys them",
        trTitle: "(Sınıf kurallarını bilir ve uyar)",
        grade: "",
      },
      {
        title: "Is very keen on doing homework",
        trTitle: "(Ödev yapmaya özen gösterir)",
        grade: "",
      },
      {
        title: "Does revision everyday",
        trTitle: "(Günlük tekrarlarını yapar)",
        grade: "",
      },
      {
        title: "Always does Grammar Book exercises by Azar",
        trTitle: "(Azar'ın Gramer kitabındaki alıştırmaları daima yapar)",
        grade: "",
      },
    ],
  },
];

export const kb_1_2 = [
  {
    header: "Listening(Dinleme)",
    skills: [
      {
        title:
          "Understands simple oral messages and instructions and recognizes  familiar words",
        trTitle:
          "(Basit sözlü iletileri ve komutları anlar ve benzer kelimeleri ayırt eder)",
        grade: "",
      },
      {
        title:
          "Identifies the main theme of an oral text and some specific details with visual aids",
        trTitle:
          "(Sözlü bir metnin ana fikrini ve bazı belirli detayları görsel eğitim araçlarıyla tanımlar )",
        grade: "",
      },
    ],
  },
  {
    header: "Talking and Conversation (Konuşma ve Sohbet)",
    skills: [
      {
        title:
          "Recites or sings a very simple song with correct pronunciation and intonation",
        trTitle:
          "(Doğru telaffuz ve tonlamayla çok basit bir şarkıyı ezberden okur ve söyler)",
        grade: "",
      },
      {
        title:
          "Answers the questions orally using the given vocabulary and structures",
        trTitle:
          "(Basit kelime ve yapıları kullanarak sorulara sözlü olarak cevap verir)",
        grade: "",
      },
      {
        title: "Tries to speak English in the classroom",
        trTitle: "(Sınıfta ingilizce konuşmaya çalışır)",
        grade: "",
      },
    ],
  },
  {
    header: "Reading (Okuma)",
    skills: [
      {
        title: "Is enthusiastic to read online Oxford Story Books",
        trTitle: "(Oxford (online ) hikaye kitaplarını okur)",
        grade: "",
      },
      {
        title:
          "Understands very simple sentences about familiar subjects with previously taught words",
        trTitle:
          "(Daha önce öğretilen kelimelerle benzer konular hakkında çok  basit cümleleri anlar)",
        grade: "",
      },
      {
        title: "Finds identified objects in pictures",
        trTitle: "(Resimlerde anlatılan konu ile alakalı nesneleri bulur)",
        grade: "",
      },
    ],
  },
  {
    header: "Learning to Learn  (Öğrenmeyi öğrenme)",
    skills: [
      {
        title:
          "Shows interest in carrying out the individual activities the teacher supervises",
        trTitle:
          "(Öğretmenin süpervizörlüğünde yapılan aktivitelere ilgi gösterir.)",
        grade: "",
      },
      {
        title: "Accepts the teacher’s instructions and expresses doubts",
        trTitle: "(Öğretmenin komutlarını anlar, anlamadıklarını ifade eder.)",
        grade: "",
      },
      {
        title: "Always does the Course Book Unit activities on-line",
        trTitle: "(Ünite  sonu  alıştırmalarını yapar)",
        grade: "",
      },
      {
        title: "Takes part in group activities with respect and interest",
        trTitle:
          "(Saygı duyarak ve ilgi göstererek grup aktivitelerine katılır)",
        grade: "",
      },
      {
        title: "Shows interest in learning English",
        trTitle: "(İngilizce öğrenmeye karşı ilgilidir)",
        grade: "",
      },
      {
        title: "Is aware of the class rules and obeys them",
        trTitle: "(Sınıf kurallarını bilir ve uyar)",
        grade: "",
      },
      {
        title: "Puts effort to learn the unit songs",
        trTitle: "(Ünitelerin şarkılarını öğrenmeye çalışır)",
        grade: "",
      },
      {
        title: "Is very keen on doing homework",
        trTitle: "(Ödev yapmaya özen gösterir)",
        grade: "",
      },
      {
        title: "Tries to involve in songs in the classroom",
        trTitle: "(Ders esnasında şarkılara eşlik etmeye özen gösterir)",
        grade: "",
      },
      {
        title: "Always joins the roleplay on activities.",
        trTitle: "(Sınıf içerisinde drama etkinliklerine daima katılır)",
        grade: "",
      },
      {
        title: "Does revision every day",
        trTitle: "(Günlük tekrarlarını yapar)",
        grade: "",
      },
    ],
  },
];

export const kb_3_4 = [
  {
    header: "Listening(Dinleme)",
    skills: [
      {
        title:
          "Understands simple oral messages and instructions and recognizes  familiar words",
        trTitle:
          "(Basit sözlü iletileri ve komutları anlar ve benzer kelimeleri ayırt eder)",
        grade: "",
      },
      {
        title:
          "Identifies the main theme of an oral text and some specific details with visual aids",
        grade: "",

        trTitle:
          "(Sözlü bir metnin ana fikrini ve bazı belirli detayları görsel eğitim araçlarıyla tanımlar )",
      },
    ],
  },
  {
    header: "Talking and Conversation (Konuşma ve Sohbet)",
    skills: [
      {
        title:
          "Recites or sings a very simple song with correct pronunciation and intonation",
        trTitle:
          "(Doğru telaffuz ve tonlamayla çok basit bir şarkıyı ezberden okur ve söyler)",
        grade: "",
      },
      {
        title:
          "Answers the questions orally using the given vocabulary and structures",
        trTitle:
          "(Verilen kelime ve yapıları kullanarak sorulara sözlü olarak cevap verir)",
        grade: "",
      },
      {
        title:
          "Takes part in group conversations and spoken interaction which arises spontaneously",
        trTitle:
          "(Grup konuşmalarına ve kendiliğinden oluşan karşılıklı konuşmalara katılır)",
        grade: "",
      },
      {
        title:
          "Gives personal information and information about his/her  surroundings daily",
        grade: "",

        trTitle:
          "(Kendisiyle ilgili ve çevrelerinde gelişen günlük olaylarla ilgili bilgi verir.)",
      },
      {
        title: "Gives simple oral presentations",
        trTitle: "(Basit sözlü sunumlar yapar)",
        grade: "",
      },
    ],
  },
  {
    header: "Reading (Okuma)",
    skills: [
      {
        title: "Is enthusiastic to read online Oxford Story Books",
        trTitle: "(Oxford (online ) hikaye kitaplarını okur)",
        grade: "",
      },
      {
        title:
          "Understands sentences about familiar subjects with previously taught words",
        grade: "",

        trTitle:
          "(Daha önce öğretilen kelimelerle benzer konular hakkında çok  basit cümleleri anlar)",
      },
    ],
  },
  {
    header: "Writing (Yazma)",
    skills: [
      {
        title:
          "Reproduces very simple words and structures using previously taught patterns",
        grade: "",

        trTitle:
          "(Daha önce öğrenilen yapıları kullanarak çok basit kelimeler ve yapılar türetir)",
      },
      {
        title: "Writes simple stories and descriptions",
        trTitle: "(Basit hikaye ve tanımlamalar yazar)",
        grade: "",
      },
      {
        title: "Fills in forms or cards with personal information and data",
        grade: "",

        trTitle:
          "(Kişisel bilgi ve verileri form ya da kartlar üzerinde doldurabilir)",
      },
    ],
  },
  {
    header: "Learning to Learn  (Öğrenmeyi öğrenme)",
    skills: [
      {
        title:
          "Shows interest in carrying out the individual activities which the teacher supervises",
        trTitle:
          "(Öğretmenin yönettiği bireysel aktiviteleri uygulamaya ilgi gösterir)",
        grade: "",
      },
      {
        title: "Accepts the teacher’s instructions and expresses doubts",
        trTitle:
          "(Öğretmenin komutlarını anlar, ve anlamadıklarını ifade eder)",
        grade: "",
      },
      {
        title: "Always does the Course Book Unit activities on-line",
        trTitle: "(Ünite sonu  alıştırmalarını daima yapar)",
        grade: "",
      },
      {
        title: "Takes part in group activities with respect and interest",
        trTitle:
          "(Saygı duyarak ve ilgi göstererek grup aktivitelerine katılır)",
        grade: "",
      },
      {
        title: "Shows interest in learning English",
        trTitle: "(İngilizce öğrenmeye karşı ilgilidir)",
        grade: "",
      },
      {
        title: "Is aware of the class rules and obeys them",
        trTitle: "(Sınıf kurallarını bilir ve uyar)",
        grade: "",
      },
      {
        title: "Is very keen on doing homework",
        trTitle: "(Ödev yapmaya özen gösterir)",
        grade: "",
      },
      {
        title: "Does revision every day",
        trTitle: "(Günlük tekrarlarını yapar)",
        grade: "",
      },
    ],
  },
];

export const kb_5_6 = [
  {
    header: "Listening(Dinleme)",
    skills: [
      {
        title:
          "Understands simple oral messages and instructions and recognizes  familiar words",
        trTitle:
          "(Basit sözlü iletileri ve komutları anlar ve benzer kelimeleri ayırt eder)",
        grade: "",
      },
      {
        title:
          "Identifies the main theme of an oral text and some specific details with visual aids",
        grade: "",

        trTitle:
          "(Sözlü bir metnin ana fikrini ve bazı belirli detayları görsel eğitim araçlarıyla tanımlar )",
      },
    ],
  },
  {
    header: "Talking and Conversation (Konuşma ve Sohbet)",
    skills: [
      {
        title:
          "Recites or sings 'a song' with correct pronunciation and intonation",
        trTitle:
          "(Doğru telaffuz ve tonlamayla çok basit bir şarkıyı ezberden okur ve söyler)",
        grade: "",
      },
      {
        title:
          "Answers the questions orally using the given vocabulary and structures",
        trTitle:
          "(Öğrendiği kelime ve yapıları kullanarak sorulara sözlü olarak cevap verir)",
        grade: "",
      },
      {
        title:
          "Takes part in group conversations and spoken interaction which arises spontaneously",
        trTitle:
          "(Grup konuşmalarına ve kendiliğinden oluşan karşılıklı konuşmalara katılır)",
        grade: "",
      },
      {
        title:
          "Gives personal information and information about his/her  surroundings daily",
        trTitle:
          "(Kendisiyle ilgili ve çevrelerinde gelişen günlük olaylarla ilgili bilgi verebilir)",
        grade: "",
      },
      {
        title: "Gives pre-intermediate level oral presentations",
        trTitle: "(Basit orta düzeyde sözlü sunum yapar)",
        grade: "",
      },
    ],
  },
  {
    header: "Reading (Okuma)",
    skills: [
      {
        title:
          "Is enthusiastic to read extra reading materials and story books the teacher gives",
        grade: "",

        trTitle:
          "(Öğretmenin verdiği fazladan metinleri ya da İngilizce okuma kitaplarını çalışmaya heveslidir)",
      },
      {
        title:
          "Understands  sentences about familiar subjects with previously taught words",
        grade: "",

        trTitle:
          "(Daha önce öğretilen kelimelerle benzer konular hakkında  cümleleri anlar)",
      },
    ],
  },
  {
    header: "Writing (Yazma)",
    skills: [
      {
        title:
          "Reproduces pre-int level sentences using previously taught patterns or structures",
        grade: "",

        trTitle:
          "(Daha önce öğrenilen yapıları kullanarak basit orta düzey kelimeler ve yapılar türetir)",
      },
      {
        title: "Writes stories and descriptions",
        trTitle: "(Hikaye ve tanımlamalar yazar)",
      },
      {
        title: "Fills in forms or cards with personal information and data",
        grade: "",

        trTitle:
          "(Kişisel bilgi ve verileri form ya da kartlar üzerinde doldurur)",
      },
    ],
  },
  {
    header: "Learning to Learn  (Öğrenmeyi öğrenme)",
    skills: [
      {
        title:
          "Shows interest in carrying out the individual activities which the teacher supervises",
        trTitle:
          "(Öğretmenin yönettiği bireysel aktiviteleri uygulamaya ilgi gösterir)",
        grade: "",
      },
      {
        title: "Accepts the teacher’s instructions and expresses doubts",
        trTitle: "(Öğretmenin komutlarını anlar ve anlamadıklarını ifade eder)",
        grade: "",
      },
      {
        title: "Always does the  Course Book Unit activities on-line",
        trTitle: "(Ünite  sonu  alıştırmalarını daima yapar)",
        grade: "",
      },
      {
        title: "Takes part in group activities with respect and interest",
        grade: "",
        trTitle:
          "(Saygı duyarak ve ilgi göstererek grup aktivitelerine katılır)",
      },
      {
        title: "Shows interest in learning English",
        trTitle: "(İngilizce öğrenmeye karşı ilgilidir)",
        grade: "",
      },
      {
        title: "Is aware of the class rules and obeys them",
        trTitle: "(Sınıf kurallarını bilir ve uyar)",
        grade: "",
      },
      {
        title: "Is very keen on doing homework",
        trTitle: "(Ödev yapmaya özen gösterir)",
        grade: "",
      },
      {
        title: "Does revision everyday",
        trTitle: "(Günlük tekrarlarını yapar)",
        grade: "",
      },
      {
        title: "Always does Grammar Book exercises by Azar",
        trTitle: "(Azar'ın Gramer kitabındaki alıştırmaları daima yapar)",
        grade: "",
      },
    ],
  },
];
