import React, { useState } from "react";

import classes from "../exam.module.scss";

function App(props) {
  const [answers, setAnswers] = useState({ o1: false, o2: false, o3: false });

  return (
    <div className={classes.examContainer}>
      <span className={classes.Heading}>{props.index + 1}</span>
      <span className={classes.QuestionText}>{props.item.email}</span>
      <div style={{ width: "90%", display: "flex", flexDirection: "row" }}>
        <div
          onClick={() => {
            setAnswers({ o1: true, o2: false, o3: false });
            props.replace(props.index, {
              email: props.item.email,
              selectedValue: props.item.selectedValue,
              selectedImage1: props.item.selectedImage1,
              selectedImage2: props.item.selectedImage2,
              selectedImage3: props.item.selectedImage3,
              answer: { o1: true, o2: false, o3: false },
            });
          }}
          style={{
            display: "flex",
            margin: "30px",
            backgroundColor: "white",
            width: "200px",
            height: "200px",
            position: "relative",
            borderRadius: "20px",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "200px", height: "200px", display: "flex" }}
            src={props.item.selectedImage1}
          />
          <label
            style={{ padding: "30px", margin: "0 auto" }}
            className="checkbox checkbox-lg checkbox-single"
          >
            <input
              onClick={() => {
                setAnswers({ o1: true, o2: false, o3: false });
                props.replace(props.index, {
                  email: props.item.email,
                  selectedValue: props.item.selectedValue,
                  selectedImage1: props.item.selectedImage1,
                  selectedImage2: props.item.selectedImage2,
                  selectedImage3: props.item.selectedImage3,
                  answer: { o1: true, o2: false, o3: false },
                });
              }}
              type="checkbox"
              checked={answers.o1 ? true : false}
            />
            <span></span>
          </label>
        </div>
        <div
          style={{
            display: "flex",
            padding: "20px",
            margin: "30px",
            backgroundColor: "white",
            width: "200px",
            height: "200px",
            borderRadius: "20px",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "200px", height: "200px" }}
            src={props.item.selectedImage2}
          />

          <label
            style={{ padding: "30px", margin: "0 auto" }}
            className="checkbox checkbox-lg checkbox-single"
          >
            <input
              onClick={() => {
                setAnswers({ o1: false, o2: true, o3: false });
                props.replace(props.index, {
                  email: props.item.email,
                  selectedValue: props.item.selectedValue,
                  selectedImage1: props.item.selectedImage1,
                  selectedImage2: props.item.selectedImage2,
                  selectedImage3: props.item.selectedImage3,
                  answer: { o1: false, o2: true, o3: false },
                });
              }}
              type="checkbox"
              checked={answers.o2 ? true : false}
            />
            <span></span>
          </label>
        </div>
        <div
          style={{
            display: "flex",
            padding: "20px",
            margin: "30px",
            backgroundColor: "white",
            width: "200px",
            height: "200px",
            borderRadius: "20px",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "200px", height: "200px", display: "flex" }}
            src={props.item.selectedImage3}
          />
          <label
            style={{ padding: "30px", margin: "0 auto" }}
            className="checkbox checkbox-lg checkbox-single"
          >
            <input
              onClick={() => {
                setAnswers({ o1: false, o2: false, o3: true });
                props.replace(props.index, {
                  email: props.item.email,
                  selectedValue: props.item.selectedValue,
                  selectedImage1: props.item.selectedImage1,
                  selectedImage2: props.item.selectedImage2,
                  selectedImage3: props.item.selectedImage3,
                  answer: { o1: false, o2: false, o3: true },
                });
              }}
              type="checkbox"
              checked={answers.o3 ? true : false}
            />
            <span></span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default App;
