import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Logo from "./londoneye1.png";
import Axios from "axios";
import classess from "./courses.module.scss";
import discountTag from "./discount-tag.png";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit">London Eye Trabzon</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export default function Pricing() {
  const classes = useStyles();

  const [response, setResponse] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [courseView, setCourseView] = useState(false);
  console.log("response", response);

  useEffect(() => {
    const getCategoryList = {
      method: "get",
      url:
        "https://london-eye.onrender.com/api/v1/user/admin/product/category/list",
    };
    const getProductList = {
      method: "get",
      url: "https://london-eye.onrender.com/api/v1/user/admin/product-list-v2",
    };

    Axios(getProductList)
      .then((response) => {
        setResponse(response.data);
      })
      .catch(function(error) {});

    Axios(getCategoryList)
      .then((response) => {
        setCategoryList(response.data);
      })
      .catch(function(error) {});
  }, []);

  useEffect(() => {
    if (!window) {
      throw new Error("DOM is unavailable");
    }
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    const tawk = document.getElementById("tawkId");
    if (tawk) {
      return window.Tawk_API;
    }
    const script = document.createElement("script");
    script.id = "tawkId";
    script.async = false;
    script.src =
      "https://embed.tawk.to/" + "5f70408f4704467e89f2a221" + "/default";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    const first_script_tag = document.getElementsByTagName("script")[0];
    if (!first_script_tag || !first_script_tag.parentNode) {
      throw new Error("DOM is unavailable");
    }
    first_script_tag.parentNode.insertBefore(script, first_script_tag);
  }, []);

  const createMarkup = (a) => {
    return { __html: a };
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <img
            src={Logo}
            onClick={() => window.location.pathname === "/"}
            style={{ width: "100px" }}
          ></img>
        </Toolbar>
        <Toolbar>
          <span
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.localStorage.removeItem("persist:v706-demo1-auth");
              window.location.reload();
            }}
          >
            Çıkış Yap
          </span>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Fiyatlandırma
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          Üyeliğinize aktif hale getirmek için ödemeyi tamamlayınız.
        </Typography>
      </Container>
      <div className={classess.tabContainer}>
        {categoryList.map((item) => (
          <div
            style={{
              backgroundColor:
                item && item.id === courseView.id ? "orange" : "",
              color: item && item.id === courseView.id ? "white" : "",
            }}
            onClick={() => setCourseView(item)}
            className={classess.tab}
          >
            <span>{item.category_name}</span>
          </div>
        ))}
      </div>
      {courseView ? (
        <>
          {response && (
            <Container maxWidth="md" component="main">
              <Grid container spacing={5} alignItems="flex-end">
                {response
                  ?.filter((tier) => tier.product_category === courseView.id)
                  .map((tier) => (
                    // Enterprise card is full width at sm breakpoint
                    <Grid
                      item
                      key={tier.product_title}
                      xs={12}
                      sm={tier.product_title === "Enterprise" ? 12 : 6}
                      md={4}
                      style={{ margin: "0 auto" }}
                    >
                      <Card>
                        <CardHeader
                          title={tier.product_title}
                          subheader={`${tier.product_month} Ay Kurs`}
                          titleTypographyProps={{ align: "center" }}
                          subheaderTypographyProps={{ align: "center" }}
                          action={
                            tier.product_discount_price != null &&
                            tier.product_discount_price != undefined &&
                            tier.product_price > tier.product_discount_price ? (
                              <img
                                src={discountTag}
                                style={{ width: "30px" }}
                              />
                            ) : null
                          }
                          className={classes.cardHeader}
                        />
                        <CardContent>
                          <div className={classes.cardPricing}>
                            <Typography
                              component="h2"
                              variant="h3"
                              color="textPrimary"
                            >
                              {tier.product_discount_price != null &&
                              tier.product_discount_price != undefined &&
                              tier.product_price > tier.product_discount_price
                                ? tier.product_discount_price
                                : tier.product_price}
                              ₺
                            </Typography>
                            <Typography variant="h6" color="textSecondary">
                              /Dönem
                            </Typography>
                          </div>
                          <div
                            dangerouslySetInnerHTML={createMarkup(
                              tier.product_description
                            )}
                          ></div>
                        </CardContent>
                        <CardActions>
                          <Link style={{ margin: "0 auto" }} href={"/checkout"}>
                            <Button
                              fullWidth
                              variant={"contained"}
                              color="primary"
                              onClick={() => {
                                if (
                                  tier.product_discount_price != null &&
                                  tier.product_discount_price != undefined &&
                                  tier.product_price >
                                    tier.product_discount_price
                                ) {
                                  window.localStorage.setItem(
                                    "set",
                                    Number(tier.product_discount_price)
                                  );
                                  window.localStorage.setItem(
                                    "productID",
                                    tier.id
                                  );
                                } else {
                                  window.localStorage.setItem(
                                    "set",
                                    Number(tier.product_price)
                                  );
                                  window.localStorage.setItem(
                                    "productID",
                                    tier.id
                                  );
                                }
                              }}
                            >
                              Ödeme
                            </Button>
                          </Link>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Container>
          )}
        </>
      ) : (
        <>
          {categoryList && (
            <Container maxWidth="md" component="main">
              <Grid container spacing={5} alignItems="flex-end">
                {categoryList.map((tier) => (
                  // Enterprise card is full width at sm breakpoint
                  <Grid
                    item
                    key={tier.category_name}
                    xs={12}
                    sm={tier.category_name === "Enterprise" ? 12 : 6}
                    md={4}
                    style={{ margin: "0 auto" }}
                  >
                    <Card>
                      <CardHeader
                        title={tier.category_name}
                        subheader={`by LondonEye`}
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        action={tier.title === "Pro" ? <StarIcon /> : null}
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            tier.category_description
                          )}
                        ></div>
                      </CardContent>
                      <CardActions>
                        <Button
                          fullWidth
                          variant={"contained"}
                          color="primary"
                          onClick={() => {
                            setCourseView(tier);
                          }}
                        >
                          Kursları Görüntüle
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          )}
        </>
      )}

      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}
