import React, { useEffect, useState } from "react";
import Axios from "axios";
import Lessons from "./LessonCard";
import { Link } from "react-router-dom";
import classes from "./watchVideoModal.module.scss";
import cambridge from "./indir.png";
import kidsbox from "./klogo.png";
import preschool from "./indir.jpg";

export default function StudentLesson() {
  const goToLink = (link) => {
    window.location = link;
  };

  const kidsboxExam = () => {
    window.location = "https://www.kidsboxapps.es/";
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flexDirection: "row", display: "flex" }}>
          <Link
            onClick={() =>
              goToLink("https://oxforddiscover2e.oxfordonlinepractice.com/")
            }
          >
            <Lessons
              img={cambridge}
              name={"Oxford LMS"}
              desc={"Oxford Learners LMS system"}
              short={"O"}
              link={"https://oxforddiscover2e.oxfordonlinepractice.com/"}
            />
          </Link>
          <Link
            onClick={() =>
              goToLink(
                "https://drive.google.com/file/d/1tNEJamqrRlrdqUgsTHxfrITLpI-rz7MR/view"
              )
            }
          >
            <Lessons
              img={preschool}
              name={"Preschool Kids"}
              desc={"Preschool Kids LMS system"}
              short={"P"}
              link={
                "https://drive.google.com/file/d/1tNEJamqrRlrdqUgsTHxfrITLpI-rz7MR/view"
              }
            />
          </Link>
          <Link onClick={() => kidsboxExam()}>
            <Lessons
              img={kidsbox}
              name={"Course Book"}
              desc={
                "Kid’s Box at Home is a website full of exciting resources to continue learning English while having fun!"
              }
              short={"K"}
              link={"https://www.kidsboxapps.es/"}
            />
          </Link>
        </div>
      </div>
    </>
  );
}
