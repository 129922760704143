import React from "react";
import { Route } from "react-router-dom";
import ExamList from "./main";
import Exam from "./exam";

function App() {
  return (
    <div>
      <Route exact path="/exams/exams" component={ExamList} />
      <Route path="/exams/exams/:id" component={Exam} />
    </div>
  );
}

export default App;
