/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  const { userType } = useSelector(
    ({ auth }) => ({
      userType: auth.user != null && auth.user.userType,
    }),
    shallowEqual
  );

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {userType === "teacher" && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/dashboard"
            )}`}
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="menu-text">Dashboard</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          {/*end::1 Level*/}
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive("/exams")}`}
          >
            <NavLink className="menu-link" to="/exams/exams">
              <span className="menu-text">Exams</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/completed"
            )}`}
          >
            <NavLink className="menu-link" to="/completed/completed">
              <span className="menu-text">Completed Exams</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/homework"
            )}`}
          >
            <NavLink className="menu-link" to="/homework/homework">
              <span className="menu-text">Homework</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/completedhomework"
            )}`}
          >
            <NavLink
              className="menu-link"
              to="/completedhomework/completedhomework"
            >
              <span className="menu-text">Completed Homework</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/certificates"
            )}`}
          >
            <NavLink className="menu-link" to="/certificates/certificates">
              <span className="menu-text">Certificates</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          {/*end::2 Level*/}
        </ul>
      )}
      {userType === "staff" && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/dashboard"
            )}`}
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="menu-text">Dashboard</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          {/*end::1 Level*/}
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/students"
            )}`}
          >
            <NavLink className="menu-link" to="/students/students">
              <span className="menu-text">Öğrenciler</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/teachers"
            )}`}
          >
            <NavLink className="menu-link" to="/teachers/teachers">
              <span className="menu-text">Öğretmenler</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>

          {/*end::2 Level*/}
        </ul>
      )}
      {userType === "admin" && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/dashboard"
            )}`}
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="menu-text">Dashboard</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          {/*end::1 Level*/}
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/students"
            )}`}
          >
            <NavLink className="menu-link" to="/students/students">
              <span className="menu-text">Öğrenciler</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/teachers"
            )}`}
          >
            <NavLink className="menu-link" to="/teachers/teachers">
              <span className="menu-text">Öğretmenler</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/products"
            )}`}
          >
            <NavLink className="menu-link" to="/products/products">
              <span className="menu-text">Urunler</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/categories"
            )}`}
          >
            <NavLink className="menu-link" to="/categories/categories">
              <span className="menu-text">Kategoriler</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/secretary"
            )}`}
          >
            <NavLink className="menu-link" to="/secretary/secretary">
              <span className="menu-text">Sekreterler</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/classes"
            )}`}
          >
            <NavLink className="menu-link" to="/classes/classes">
              <span className="menu-text">Sınıflar</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive("/exams")}`}
          >
            <NavLink className="menu-link" to="/exams/exams">
              <span className="menu-text">Sınavlar</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/lectures"
            )}`}
          >
            <NavLink className="menu-link" to="/lectures/lectures">
              <span className="menu-text">Dersler</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/videos"
            )}`}
          >
            <NavLink className="menu-link" to="/videos/videos">
              <span className="menu-text">Videolar</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/homeworks"
            )}`}
          >
            <NavLink className="menu-link" to="/homeworks/homeworks">
              <span className="menu-text">Ödevler</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>

          {/*end::2 Level*/}
        </ul>
      )}
      {userType === "student" && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/dashboard"
            )}`}
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="menu-text">Dashboard</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          {/*end::1 Level*/}
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/lessons"
            )}`}
          >
            <NavLink className="menu-link" to="/lessons/lessons">
              <span className="menu-text">Lessons</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/homework"
            )}`}
          >
            <NavLink className="menu-link" to="/homework/homework">
              <span className="menu-text">Homework</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive("/books")}`}
          >
            <NavLink className="menu-link" to="/books/books">
              <span className="menu-text">Books</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/attendance"
            )}`}
          >
            <NavLink className="menu-link" to="/attendance/attendance">
              <span className="menu-text">Attendance</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive("/exams")}`}
          >
            <NavLink className="menu-link" to="/exams/exams">
              <span className="menu-text">Exam</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/certificates"
            )}`}
          >
            <NavLink className="menu-link" to="/certificates/certificates">
              <span className="menu-text">Certificates</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
          {/*end::2 Level*/}
        </ul>
      )}
    </div>
  );
}
