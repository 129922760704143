import axios from "axios";

export const LOGIN_URL = "https://london-eye.onrender.com/api/v1/user/login/";
export const REGISTER_URL =
  "https://london-eye.onrender.com/api/v1/user/register";
export const REQUEST_PASSWORD_URL =
  "https://london-eye.onrender.com/api/v1/user/forgot/password/";

export const ME_URL =
  "https://london-eye.onrender.com/api/v1/user/current-user";

export function login(username, password) {
  return axios.post(LOGIN_URL, {
    username: username,
    password: password,
  });
}

export function register(email, first_name, last_name, username, password) {
  return axios.post(REGISTER_URL, {
    email: email,
    first_name: first_name,
    last_name: last_name,
    username: username,
    password: password,
    password1: password,
    userType: "student",
  });
}

export function requestPassword(username) {
  return axios.post(REQUEST_PASSWORD_URL, { username });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
