/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import Axios from "axios";
import Select from "react-select";
import classes from "./askDemoLink.module.scss";

export function TilesWidget13({ className, widgetHeight = "175px" }) {
  const backgroundImageUrl = toAbsoluteUrl("/media/svg/patterns/taieri.svg");
  const [open, setOpen] = useState(false);
  const [sms, setSms] = useState();

  const closeModal = () => setOpen(false);
  const [classList, setClassList] = useState([]);
  const [selectedClass, setSelectedClass] = useState();
  useEffect(() => {
    const getClassList = {
      method: "get",
      url: "https://london-eye.onrender.com/api/v1/user/admin/class-list",
    };

    Axios(getClassList)
      .then((response) => {
        const optionEx = response.data.results.map((a) => ({
          value: a.id,
          label: a.Class_name,
        }));
        setClassList(optionEx);
      })
      .catch(function(error) {});
  }, []);

  const postSms = () => {
    const postSmsEndpoint = {
      method: "post",
      url: "https://london-eye.onrender.com/api/v1/staff/deneme",
      data: {
        text: sms,
      },
    };
    Axios(postSmsEndpoint)
      .then((response) => {
        if (response.status === 200) {
          setOpen(false);
        }
      })
      .catch(function(error) {});
  };

  return (
    <>
      <div
        className={`card card-custom bgi-no-repeat ${className}`}
        style={{
          height: widgetHeight,
          backgroundColor: "#663259",
          backgroundPosition: "calc(100% + 0.5rem) 100%",
          backgroundSize: "100% auto",
          backgroundImage: `url("${backgroundImageUrl}")`,
        }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex align-items-center">
          <div>
            <h3 className="text-white font-weight-bolder line-height-lg mb-5">
              Toplu SMS
              <br />
              Gönderme
            </h3>
            <a
              onClick={() => setOpen((o) => !o)}
              className="btn btn-success font-weight-bold px-6 py-3"
            >
              SMS Oluştur
            </a>
            <Popup
              open={open}
              modal
              nested
              position="center center"
              closeOnDocumentClick
              onClose={closeModal}
            >
              <>
                <Formik onSubmit={() => postSms()}>
                  {({ handleSubmit, setFieldValue }) => (
                    <>
                      <Modal.Body className="overlay overlay-block cursor-default">
                        <Form className="form form-label-right">
                          <div className="form-group row">
                            <div
                              style={{ marginBottom: "20px" }}
                              className="col-lg-12"
                            >
                              <label className={classes.datePickerLabel}>
                                Select Class
                              </label>
                              <Select
                                name={"selectedClass"}
                                placeholder={"Select Class"}
                                component={Select}
                                style={{ zIndex: "9999999 !important" }}
                                value={classList.filter(
                                  (option) => option.value === selectedClass
                                )}
                                onChange={(val) => {
                                  setSelectedClass(val.value);
                                }}
                                type={selectedClass}
                                tabSelectsValue={selectedClass}
                                options={classList}
                              />
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>Sms Icerigi</label>
                                <Field
                                  name="description"
                                  as="textarea"
                                  className="form-control"
                                  onChange={(val) => {
                                    setSms(val.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          type="button"
                          onClick={closeModal}
                          className="btn btn-light btn-elevate"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          onClick={() => postSms()}
                          className="btn btn-primary btn-elevate"
                        >
                          Send SMS
                        </button>
                      </Modal.Footer>
                    </>
                  )}
                </Formik>
              </>
            </Popup>
          </div>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
