import React, { Component } from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import { Line, Circle } from "rc-progress";
import upload from "./uploadq.svg";
import classes from "./askDemoLink.module.scss";
import Checked from "./checked.svg";
export default class ProfilePage extends Component {
  state = {
    avatar: "",
    isUploading: false,
    progress: 0,
    avatarURL: "",
    foldername: "london",
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = (progress) => this.setState({ progress });
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  // handleUploadSuccess = (filename) => {
  //   this.setState({ avatar: filename, progress: 100, isUploading: true });
  //   firebase
  //     .storage()
  //     .ref(this.state.foldername)
  //     .child(filename)
  //     .getDownloadURL()
  //     .then((url) => this.setState({ avatarURL: url }));
  // };

  render() {
    console.log(this.state);
    return (
      <div
        style={{
          display: "flex",
          margin: "15px",
        }}
      >
        <p style={{ display: "flex" }}>{this.props.title}</p>
        <form style={{ display: "flex" }}>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "300px",
                height: "100%",
                margin: "0 auto",
                position: "relative",
                justifyContent: "center",
              }}
            >
              {this.state.isUploading && (
                <div
                  style={{
                    marginBottom: "50px",
                    display: "flex",
                    marginLeft: "25px",
                  }}
                >
                  <Line
                    width="250px"
                    percent={this.state.progress}
                    strokeWidth="4"
                    strokeColor="#2ecc71"
                  />
                </div>
              )}
              <CustomUploadButton
                accept={`${this.state.foldername}/*`}
                name="avatar"
                randomizeFilename
                storageRef={firebase.storage().ref(this.state.foldername)}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                // onUploadSuccess={this.handleUploadSuccess}
                onUploadSuccess={this.props.success}
                onProgress={this.handleProgress}
                style={{
                  width: "300px",
                  height: "50px",
                  backgroundColor: "#bdc3c7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  borderRadius: "5px",
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.12)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={this.state.progress === 100 ? Checked : upload}
                    style={{ width: "25px", height: "25px", margin: "5px" }}
                  />

                  <span style={{ color: "2980b9" }}> Upload Homework</span>
                </div>
              </CustomUploadButton>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
