import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import ReactLoading from "react-loading";
import { Base64 } from "js-base64";
import { shallowEqual, useSelector } from "react-redux";

export default function CheckOutForm() {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user != null && auth.user.id,
    }),
    shallowEqual
  );
  const { userEmail } = useSelector(
    ({ auth }) => ({
      userEmail: auth.user != null && auth.user.email,
    }),
    shallowEqual
  );

  const script = useRef();
  const [response, setResponse] = useState();

  useEffect(() => {
    const data = window.localStorage.getItem("data");
    const decoded = JSON.parse(Base64.decode(data));
    const configCategoryList = {
      method: "post",
      url: "https://london-eye.onrender.com/api/v1/user/payment-initialize",
      data: {
        form_data: {
          locale: "TR",
          conversationId: "123456789",
          price: window.localStorage.getItem("set"),
          paidPrice: window.localStorage.getItem("set"),
          currency: "TR",
          paymentGroup: "TR",
          callbackUrl: `https://portal.londoneyedilokullari.com/check?${window.localStorage.getItem(
            "yToken"
          )}`,
          enabledInstallments: [1],
          buyer: {
            product: window.localStorage.getItem("productID"),
            id: user,
            name: decoded.firstName,
            surname: decoded.lastName,
            gsmNumber: decoded.phoneNumber,
            email: userEmail,
            identityNumber: decoded.tcKimlik,
            registrationAddress: decoded.adress,
            city: decoded.il,
            country: "Türkiye",
            zipCode: decoded.zipCode,
          },
          shippingAddress: {
            contactName: `${decoded.firstName} ${decoded.lastName}`,
            city: decoded.adress,
            country: "Türkiye",
            address: decoded.adress,
            zipCode: decoded.zipCode,
          },
          billingAddress: {
            contactName: `${decoded.firstName} ${decoded.firstName}`,
            city: decoded.fadress,
            country: "Türkiye",
            address: decoded.fadress,
            zipCode: decoded.fzipCode,
          },
        },
      },
    };

    Axios(configCategoryList)
      .then((response) => {
        if (response.data.status === "success") {
          window.localStorage.setItem("yToken", response.data.token);

          window.localStorage.removeItem("data");
        } else {
          // window.location.reload();
        }
        setResponse(response.data);
      })
      .catch(function(error) {});
  }, []);

  if (!response) {
    return (
      <div
        style={{
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading
          type={"spinningBubbles"}
          color={"#17c191"}
          height={200}
          width={200}
        />
      </div>
    );
  }

  if (response) {
    const a = document.getElementById("iyzipay-checkout-form");
    console.log("a", a);
  }
  console.log("response", response);
  return (
    <>
      <div>
        {response && (
          <iframe
            style={{
              width: "100%",
              height: "900px",
              borderWidth: "0px",
              borderStyle: "none",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
            scrolling="no"
            src={`${response.paymentPageUrl}&iframe=true`}
          >
            <div
              id="iyzipay-checkout-form"
              ref={script}
              class="responsive"
            ></div>
          </iframe>
        )}
      </div>
    </>
  );
}
