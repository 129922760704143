import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Uploader from "./musicUploader";
import firebase from "firebase";
import ReactLoading from "react-loading";
import { shallowEqual, useSelector } from "react-redux";
import DownloadIcon from "./1040236.svg";
import Checked from "./checked.svg";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "90%",
    height: "70vh",
    padding: 10,
  },
});

export default function StudentLesson(props) {
  const { userId } = useSelector(
    ({ auth }) => ({
      userId: auth.user != null && auth.user.id,
    }),
    shallowEqual
  );
  const [status, setStatus] = useState([]);
  const [url, setUrl] = useState("");
  const [data, setData] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [classId, setCid] = useState();
  const [homeworkUrl, setHomeworkUrl] = useState();

  const id = props.location.pathname.split("/homework/homework/")[1];

  useEffect(() => {
    const getLessons = {
      method: "get",
      url: `https://london-eye.onrender.com/api/v1/student/my-homeworks/detail/${id}`,
      data: {
        exam: id,
      },
    };
    Axios(getLessons)
      .then((response) => {
        setStatus(response.data.status);
        setData(response.data);
        setUrl(response.data.HomeWork_Url);
        setCid(response.data.Class);
      })
      .catch(function(error) {});
  }, []);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const onSubmitForm = () => {
    const postExam = {
      method: "post",
      url: "https://london-eye.onrender.com/api/v1/student/upload-homework",
      data: {
        student: userId,
        Class: classId,
        homeWork: Number(id),
        student_answers: homeworkUrl,
      },
    };
    Axios(postExam)
      .then((response) => {
        if (response.status === 201) {
          props.history.push("/homework/homework");
        }
      })
      .catch(function(error) {});
  };
  if (!data) {
    return (
      <ReactLoading
        type={"spinningBubbles"}
        color={"#f0f0f0"}
        height={667}
        width={375}
      />
    );
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "white",
          padding: "30px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <span
              style={{
                marginBottom: "16px",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              {data.HomeWork_name}
            </span>
            <span style={{ fontSize: "14px", fontWeight: "500" }}>
              {data.HomeWork_description}
            </span>
            <div
              style={{
                marginTop: "50px",
                display: "flex",
                padding: "30px",
                justifyContent: "center",
                width: "300px",
                height: "50px",
                border: "1px solid #1d1d1d",
                alignItems: "center",
              }}
            >
              <a
                href={url}
                target="_blank"
                style={{
                  color: "#1d1d1d",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Inspect & Download Homework
              </a>

              <img
                href={url}
                target="_blank"
                src={DownloadIcon}
                style={{ width: "40px", height: "40px" }}
              />
            </div>
          </div>
          <div style={{ display: "flex", flex: "1", justifyContent: "center" }}>
            <div>
              <Uploader
                success={(filename) => {
                  firebase
                    .storage()
                    .ref("london")
                    .child(filename)
                    .getDownloadURL()
                    .then((url) => {
                      console.log("Homework Url", url);
                      setHomeworkUrl(url);
                    });
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              justifyContent: "center",
              margin: "75px auto",
              display: "flex",
            }}
          >
            <button
              style={{ width: "300px", height: "50px" }}
              disabled={url ? false : true}
              onClick={() => {
                onSubmitForm();
              }}
              className="btn btn-success font-weight-bolder font-size-sm"
            >
              Submit Homework
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
