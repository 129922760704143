import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Formik, Form, Field, FieldArray } from "formik";
import { getComponent } from "./questionHandler";
import { shallowEqual, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import study from "./study.svg";
import classes from "./installToStore.module.scss";
import Countdown from "react-countdown";
import time from "./fast-time.svg";
export default function StudentLesson(props) {
  const [questions, setQuestions] = useState([]);
  const [classId, setCID] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [sos, setS] = useState();
  const [h, setH] = useState();
  const [m, setM] = useState();

  const [response, setResponse] = useState();
  const [count, setCount] = useState();

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0.7,
            backgroundColor: "black",
            right: 0,
          }}
        >
          <div
            style={{
              height: "30vh",
              width: "500px",
              backgroundColor: "#3e5e6e",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItem: "center",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
                flexDirection: "column",
                padding: "30px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img style={{ width: "30px", height: "30px" }} src={time} />
                <span
                  style={{
                    margin: "0 auto",
                    display: "flex",
                    color: "#000",
                    fontSize: "30px",
                    marginBottom: "50px",
                  }}
                >
                  Exam time is over!
                </span>
              </div>
              <button
                className="btn btn-secondary btn-lg"
                onClick={() => props.history.push("/exans/exams")}
              >
                Back to Dashboard
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      // Render a countdown
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "300px",
          }}
        >
          <img
            style={{ width: "80px", height: "80px", margin: "10px" }}
            src={time}
          />
          <span className={classes.timeLabel}>
            {hours}:{minutes}:{seconds}
          </span>
        </div>
      );
    }
  };

  const id = props.location.pathname.split("/exams/exams/")[1];
  const { userId } = useSelector(
    ({ auth }) => ({
      userId: auth.user != null && auth.user.id,
    }),
    shallowEqual
  );

  useEffect(() => {
    const getQuestions = {
      method: "get",
      url: `https://london-eye.onrender.com/api/v1/student/my-exam/detail/${id}`,
      data: {
        exam: id,
      },
    };
    const getDuration = {
      method: "post",
      url: `https://london-eye.onrender.com/api/v1/student/duration`,
      data: {
        exam: id,
      },
    };
    console.log("asdfadsfa", Date.now());

    Axios(getDuration)
      .then((response) => {
        setResponse(response);
        if (response.data.status === "success") {
          console.log(response.data.duration);
          setMinutes(response.data.duration.slice(0, 7));
          var hms = response.data.duration.slice(0, 7); // your input string
          console.log(hms);
          var a = hms.split(":"); // split it at the colons
          var fas = +a[0] * 3600000 + +a[1] * 60000 + +a[2] * 1000;
          setS(fas);
          console.log("fas", fas);
          Axios(getQuestions)
            .then((response) => {
              setCID(response.data.Class);
              setQuestions(response.data.question);
            })
            .catch(function(error) {});
        }
      })
      .catch(function(error) {});
  }, []);

  const getInitialValues = () => {
    return {
      examQuestions: questions,
    };
  };

  const onSubmitForm = (values) => {
    console.log(values);
    const postExam = {
      method: "post",
      url: "https://london-eye.onrender.com/api/v1/student/submit-exam",
      data: {
        student: userId,
        Class: classId,
        exam: id,
        student_answers: values.examQuestions,
      },
    };
    Axios(postExam)
      .then((response) => {
        if (response.status === 201) {
          props.history.push("/exams/exams");
        }
      })
      .catch(function(error) {});
  };

  if (!response) {
    return (
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center" }}
      >
        <ReactLoading
          type={"spinningBubbles"}
          color={"#fca652"}
          height={667}
          width={375}
        />
      </div>
    );
  }
  return (
    <>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {minutes ? (
          <div
            style={{
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              minHeight: "100vh",
              width: "100%",
              backgroundColor: "white",
            }}
          >
            {sos && (
              <div
                style={{
                  width: "100vw",
                  backgroundColor: "#9AE19D",
                  right: 0,
                  position: "fixed",
                  zIndex: 999,

                  top: 0,
                  height: "70px",
                }}
              >
                <div className={classes.schedulerContainer}>
                  <div className={classes.time}>
                    <Countdown date={Date.now() + sos} renderer={renderer} />
                  </div>
                </div>
              </div>
            )}
            <Formik
              enableReinitialize
              initialValues={getInitialValues()}
              onSubmit={(values) => onSubmitForm(values)}
            >
              {({
                values,
                errors,
                handleChange,
                touched,
                setFieldValue,
                handleSubmit,
              }) => (
                <Form style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <FieldArray name="examQuestions">
                      {({ push, remove, replace, insert }) => (
                        <div style={{ padding: "20px", width: "100%" }}>
                          {values.examQuestions.map(
                            (email, index, selectedValue) => {
                              return getComponent(
                                email,
                                index,
                                values,
                                setFieldValue,
                                replace
                              );
                            }
                          )}
                        </div>
                      )}
                    </FieldArray>
                    <button className="btn btn-primary btn-lg" type="submit">
                      Submit Exam
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <div
            style={{
              width: "750px",
              height: "500px",
              backgroundColor: "#3e5e6e",
              borderRadius: "20px",
              padding: 20,
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <div
              style={{
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src={study}
                style={{ width: "100px", margin: "20px auto" }}
              ></img>
              <span style={{ color: "white", fontSize: "30px" }}>
                Exam is not available
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
