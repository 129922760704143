import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
console.log("test22222");

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal() {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Text in a modal</h2>
      <p id="simple-modal-description">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </p>
      <SimpleModal />
    </div>
  );

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#1d1d1d",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "500px",
          height: "300px",
          background: "white",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <h3>Ödemeniz Başarıyla Tamamlandı</h3>
        <h3 style={{ margin: "10px" }}>Sınıf Ataması Beklenmektedir</h3>
        <h3 style={{ margin: "10px" }}>
          Sınıf atamasıyla birlikte uygulamayı kullanmaya başlayabilirsiniz
        </h3>

        <div
          style={{
            width: "75%",
            height: "50px",
            background: "darkorange",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            cursor: "Pointer",
          }}
          onClick={() => {
            window.localStorage.removeItem("persist:v706-demo1-auth");
            window.location.reload();
          }}
        >
          <span
            style={{ margin: "0 auto", fontSize: "20px", color: "#1d1d1d" }}
          >
            Çıkış Yap
          </span>
        </div>
      </div>
    </div>
  );
}
