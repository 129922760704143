import React from "react";

import classes from "../exam.module.scss";

function App(props) {
  return (
    <div className={classes.examContainer}>
      <span className={classes.Heading}>{props.index + 1}</span>
      <span className={classes.QuestionText}>{props.item.email}</span>
      <textarea
        onChange={(val) =>
          props.replace(props.index, {
            email: props.item.email,
            selectedValue: props.item.selectedValue,
            answer: val.target.value,
          })
        }
        style={{ width: "100%", height: "300px" }}
      />
    </div>
  );
}

export default App;
