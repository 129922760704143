import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/StudentDashboardPage";
import Lessons from "./modules/StudentLessons/index";
import Homeworks from "./modules/StudentHomeworks/index";
import Exams from "./modules/StudentExams/index";
import Books from "./modules/StudentBooks/index";
import Attendace from "./modules/StudentAttendance/index";
import Certificates from "./modules/StudentKarne/index";

const Classes = lazy(() => import("./modules/Classes/pages/ClassesPage"));
const Lectures = lazy(() => import("./modules/Lectures/pages/LecturePage"));

export default function AdminPage() {
  useEffect(() => {
    if (!window) {
      throw new Error("DOM is unavailable");
    }
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    const tawk = document.getElementById("tawkId");
    if (tawk) {
      return window.Tawk_API;
    }
    const script = document.createElement("script");
    script.id = "tawkId";
    script.async = false;
    script.src =
      "https://embed.tawk.to/" + "5f70408f4704467e89f2a221" + "/default";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    const first_script_tag = document.getElementsByTagName("script")[0];
    if (!first_script_tag || !first_script_tag.parentNode) {
      throw new Error("DOM is unavailable");
    }
    first_script_tag.parentNode.insertBefore(script, first_script_tag);
  }, []);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />\
        <Route path="/check" component={DashboardPage} />
        <Route path="/lessons" component={Lessons} />
        <Route path="/homework" component={Homeworks} />
        <Route path="/classes" component={Classes} />
        <Route path="/lectures" component={Lectures} />
        <Route path="/exams" component={Exams} />
        <Route path="/books" component={Books} />
        <Route path="/attendance" component={Attendace} />
        <Route path="/certificates" component={Certificates} />
        {/* <Route path="/secretary" component={Secretary} /> */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
